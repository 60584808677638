import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import HomeIcon from '@mui/icons-material/Home';
import { DarkMode, LightMode } from "@mui/icons-material";
import { styled } from '@mui/material/styles';


import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "../../features/themeSlice";
import { StyledMenuItem } from "./StyledMenuItem";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHomeRedirect = () => {
    const origin = window.location.origin;
    const newURL = origin + "/home";
    window.location.href = newURL;
  };

  const openProfile = () => {
    const origin = window.location.origin;
    const newURL = origin + "/profile";
    window.location.href = newURL;
  }

  const mode = useSelector((state: any) => state.theme.mode);
  const dispatch = useDispatch();
  const theme = useTheme();
  const dark = theme.palette.neutral.dark;
  const iconColor = theme.palette.iconColor;

  return (
    <>
      
      <Tooltip 
        title="ПРОФИЛ" 
        arrow
        slotProps={{
          popper: {
            sx: {
              [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                {
                  marginTop: '0px',
                  fontSize: '1.4rem',
                  fontFamily: 'Ubuntu',
                  color: 'rgba(0, 0, 0, 0.87)',
                  maxWidth: 220,
                  backgroundColor: '#f5f5f9',
                  border: '1px solid #dadde9',
                },
            },
          },
        }}
      >
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            sx={{ width: 32, height: 32 }}
            src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
          />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            }
          },
        }}

      >
        {/* ПРОФИЛ */}
        <StyledMenuItem onClick={openProfile}>
          <Avatar src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" />{" "}
          <Typography sx={{ fontFamily: "Ubuntu", color: "#55c57a" }}>Илиян Неделчев</Typography>
        </StyledMenuItem>

        <Divider />

        {/* НАЧАЛО */}
        <MenuItem onClick={() => handleHomeRedirect()}>
          <ListItemIcon>
            <HomeIcon sx={{ color: iconColor }} fontSize="large" />
          </ListItemIcon>
          <Typography sx={{ fontFamily: "Ubuntu", fontWeight: "400" }}>Начало</Typography>
        </MenuItem>

        {/* СМЕНИ ТЕМАТА */}
        <MenuItem onClick={() => dispatch(setMode())}>
          <ListItemIcon>
            {theme.palette.mode === "dark" ? (
              <DarkMode sx={{ color: iconColor}} fontSize="large"/>
            ) : (
              <LightMode sx={{ color: iconColor }} fontSize="large"/>
            )}
          </ListItemIcon>
          <Typography sx={{ fontFamily: "Ubuntu", fontWeight: "400" }}>Смени темата</Typography>
        </MenuItem>

        {/* НАСТРОЙКИ */}
        <MenuItem>
          <ListItemIcon>
            <Settings sx={{ color: iconColor }} fontSize="large" />
          </ListItemIcon>
          <Typography sx={{ fontFamily: "Ubuntu", fontWeight: "400" }}>Настройки</Typography>
        </MenuItem>

        {/* ИЗХОД */}
        <MenuItem>
          <ListItemIcon>
            <Logout sx={{ color: iconColor }} fontSize="large" />
          </ListItemIcon>
          <Typography sx={{ fontFamily: "Ubuntu", fontWeight: "400" }}>Изход</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
