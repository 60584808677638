import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../app/store";


interface textContent {
  content: string;
  isRead: boolean;
}

export interface messagesStruct {
  img: string;
  name: string;
  text: textContent;
}

const initialState = {
  messages: [] as messagesStruct[],
  unreadMessages: 0,
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<messagesStruct[]>) => {
      state.messages = action.payload.sort(
        (a: messagesStruct, b: messagesStruct) => {
          if (a.text.isRead && !b.text.isRead) {
            return 1;
          } else if (!a.text.isRead && b.text.isRead) {
            return -1;
          }
          return 0;
        }
      );

      state.unreadMessages = state.messages.filter(
        (el: messagesStruct) => el.text.isRead === false
      ).length;
    },
    markMessageAsRead: (state, action: PayloadAction<messagesStruct>) => {
      const messageId = action.payload.name;
      const messageToMark = state.messages.find(
        (message) => message.name === messageId
      );

      if (messageToMark) {
        messageToMark.text.isRead = true;
        state.unreadMessages = state.messages.filter(
          (el: messagesStruct) => el.text.isRead === false
        ).length;
      }
    },
  },
});

export const { setMessages, markMessageAsRead } = messagesSlice.actions;
export default messagesSlice.reducer;

export const useTypedSelectorMessage: TypedUseSelectorHook<RootState> = useSelector;
