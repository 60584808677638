import { useState, useEffect } from "react";
import ProfileHeader from "./ProfileHeader";
import ProfileSummary from "./ProfileSummary";
import DogProfile from "./DogProfile";
import { Navbar } from "../../../navbar/Navbar";
import "./userProfile.css";

export const UserProfile = () => {

  return (
    <div className="user-profile-cont">
      {/* <ProfileHeader></ProfileHeader> */}
      <Navbar />
      <div className="main">
        <div className="content">
          <ProfileSummary></ProfileSummary>
          <DogProfile></DogProfile>
        </div>
      </div>
    </div>
  );
};
