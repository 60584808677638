import React, { useEffect, useState } from "react";
import "./gdpr.css";
import { Footer } from "../../landing/footer/Footer";

export const GDPR: React.FC = () => {

    return (
        <div className="gdpr-main">
            <div className="gdpr-heading">
                <div className="cont-row">
                    <div className="glitch_wrap">
                        <div className="header__logo glitch"></div>
                    </div>
                    <div className="glitch-text_wrap" style={{ marginLeft: "-2vw"}}>
                        <p data-text="PetConnect" className="glitch-text fast-anim">PetConnect</p>
                    </div>
                </div>
            </div>

            <div className="sections">

                <h1>Уведомление за поверителност при обработката на лични данни за целите на получаването на търговски и маркетингови съобщения на „Пет Кънект“ ООД</h1>

                <section>
                    <h2>Кои сме ние?</h2>
                    <p>Администратор на личните данни, събрани и обработвани чрез интернет-страницата www.petconnect.bg („Уебсайтът“), е дружеството „Пет Кънект“ ООД („ние“, „нас“, „наши“, „Дружеството“, „Администратор/ът“) – в процес на учредяване.</p>
                    <p>До вписването на Дружеството в Търговския регистър към Агенцията по вписванията, лична отговорност за законосъобразното обработване на Вашите лични данни носят съдружниците в Дружеството – г-н Иван Данчев Данчев, г-н Димитър Ангелов Пенев, г-н Илиян Неделчев Неделчев и г-н Иван Иванов Странски.</p>
                    <p>Можете да се свържете в представителите на Дружеството посредством следния имейл адрес: <span>help@petconnect.global</span> или на тел: <span>+359 894 457 378</span>
                    </p>
                </section>

                <section>
                    <h2>Ние ценим високо поверителността на Вашите лични данни и се грижим за тяхната защита.</h2>
                    <p>Защитата на личната Ви информация през целия процес на обработка на лични данни, както и сигурността на всички бизнес данни, е важен въпрос за нас.</p>
                    <p>Ние обработваме личните данни, събрани чрез формата за абониране за получаването на информация относно развитието на нашата платформа и изпращането на последващи маркетингови съобщения, поместена на Уебсайта, поверително и в съответствие със законовите разпоредби на национално и европейско ниво. </p>
                    <p>Защитата на данните и сигурността на информацията са включени в корпоративната ни политика.</p>
                </section>

                <section>
                    <h2>Каква информация ще получите от това Уведомление?</h2>
                    <p>Посредством това Уведомление Вие ще получите изчерпателна информация на ясен и достъпен език за това какви действия се извършват с личните данни, които предоставяте на „Пет Кънект“ ООД, включително:</p>
                    <ul>
                        <li>Какви лични данни събираме за Вас?</li>
                        <li>Каква е целта на тяхното събиране?</li>
                        <li>По какъв начин и за какъв срок съхраняваме предоставените лични данни?</li>
                        <li>С кого споделяме Вашите лични данни?</li>
                        <li>Какви са Вашите права относно предоставените лични данни?</li>
                    </ul>
                </section>

                <section>
                    <h2>Какво са лични данни?</h2>
                    <p>По смисъла на Регламент (ЕС) 2016/679 на Европейския парламент и на Съвета от 27 април 2016 година относно защитата на физическите лица във връзка с обработването на лични данни и относно свободното движение на такива данни и за отмяна на Директива 95/46/EО („Общ регламент относно защитата на данните“, „ОРЗД“, „GDPR“) и Закона за защита на личните данни („ЗЗЛД“) лични данни са всякаква информация, свързана с идентифицирано физическо лице или физическо лице, което може да бъде идентифицирано пряко или непряко („субект на данни“). 
                    </p>
                    <p>Така напр. непряко идентифициране е номерът на мобилния Ви телефон или Вашият имейл адрес, ако той съдържа Вашето име. Пряко идентифициране се постига, когато предоставите уникален идентификатор за Вас като например три имена, ЕГН, ЛНЧ и др.</p>
                    <p></p>
                </section>

                <section>
                    <h2>Каква информация събираме за Вас посредством нашата форма за абониране?</h2>
                    <p>Посредством формата за абониране, поместена на нашия Уебсайт, ние събираме и обработваме единствено Вашето име и имейл адрес, които представляват лични данни по смисъла на чл. 4, пар. 1 от ОРЗД.</p>
                    <p>Дружеството не събира и не обработва специални категории лични по смисъла на чл. 9 от ОРЗД, посредством формата за абониране.</p>
                </section>

                <section>
                    <h2>На какво основание „Пет Кънект“ ООД събира и обработва Вашите лични данни?</h2>
                    <p>Вашите лични данни, събирани и обработвани посредством формата за абониране, налична на нашата Уебстраница, се обработват на основание Вашето изрично, свободно и недвусмислено предоставено от Вас съгласие, като правното основание за това е в чл. 6, пар. 1, б. „а“ от ОРЗД.</p>
                    <p>Съгласието за обработване на личните Ви данни се предоставя чрез съответното маркиране на бутон на нашата интернет-страница за получаване на маркетингови съобщения и известия.</p>
                    <p>Съгласието, което предоставяте може винаги да бъде оттеглено чрез изпращане на писмено заявление на следния имейл адрес: <span>help@petconnect.global</span></p>
                    <p>Съгласието, изразено чрез маркиране на бутона за получаване на маркетингови съобщения и известия, може да бъде оттеглено винаги, като върнете отговор на полученото търговско съобщения на Вашата електронна поща.</p>
                    <p>Обработването на Вашите лични данни за целите на изграждането на нашата вътрешна база данни, чрез която да можем да Ви изпращаме персонализирани оферти и информация относно промоционални кампании относно наши продукти и услуги, както и информация относно развитието на нашата платформа, се основава на нашия легитимен интерес, освен когато пред този интерес преимущество имат Вашите интереси или основните Ви права и свободи, като правното основание за това обработване е чл. 6, пар. 1, б. „е“ от ОРЗД.</p>
                </section>

                <section>
                    <h2>За какви цели обработваме Вашите лични данни?</h2>
                    <p>Личните данни, които събираме и обработваме за Вас посредством нашата форма за абониране се използват за следните цели:</p>
                    <ul>
                        <li> За да Ви поддържаме информирани относно всяко развитие на нашата платформа, включително за нейното първоначално стартиране, предлаганите продукти и услуги, нейното разширяване и включването на нови продукти и услуги;
                        </li>
                        <li>За да изградим нашата вътрешна база данни, която да бъде използвана за изпращането на последващи търговски и маркетингови съобщения (в случай че разполагаме с Вашето изрично, свободно и недвусмислено предоставено съгласие за това).</li>
                    </ul>
                </section>


                <section>
                    <h2>Как и за какъв срок съхраняваме Вашите лични данни?
                    </h2>
                    <p>Обработваните от нас Ваши лични данни се съхраняват изключително в Европейския съюз като част от облака Azure EU Cloud - облачен сървър, предоставен като продукт Microsoft Azure Cloud и са защитени с всички необходими и адекватни технически и организационни мерки за защита на тяхната достъпност, интегритет и наличност.</p>
                    <p>По-конкретно:</p>
                    <ul>
                        <li>Всички центрове за данни са сертифицирани по ISO27001 и ISO27018: <a href="https://learn.microsoft.com/bg-bg/azure/security/fundamentals/infrastructure" target="_blank">https://learn.microsoft.com/bg-bg/azure/security/fundamentals/infrastructure</a>;</li>
                        <li>Извършва се криптиране на всички данни със стандартни за индустрията продукти за криптиране по време на прехвърляне и в покой;</li>
                        <li>Внедрена е заключваща кутия за клиенти, която гарантира, че Microsoft няма достъп до съдържание без изричното ни съгласие;</li>
                        <li>В случай че данните Ви се предават към основните сървъри на Microsoft в Съединените Американски Щати, са сключени договори за обработка на поръчки и стандартни договорни клаузи на ЕС ((ЕС) 2021/915, 4 юни 2021 г., модули 2 и 3);</li>
                        <li>Извършена е Оценка на въздействието на трансфера (ОВТ), извършена от външен експерт по защита на данните.</li>
                    </ul>
                    <p>Вашите лични данни, използвани за целите на изпращането на информационни, търговски и маркетингови съобщения се обработват за срока, за който е предоставено Вашето изрично съгласие (т.е. до неговото оттегляне), но ние се задължаваме да поискаме отново Вашето ново съгласие с изтичането на срок от 2 (две) години.</p>
                    <p>Вашите лични данни, които биват включени в нашата база данни, които обработваме на база нашия легитимен интерес, се съхраняват за срок от 5 (пет) години, считано от тяхното предоставяне, в случай че Вие не сте пожелали ограничаване на тяхното обработване или тяхното изтриване преди изтичането на този срок.</p>
                    <p>Сроковете за съхраняване, посочени по-горе, могат да бъдат удължени, в случай че по отношение на Дружеството съществуват такива законови задължения, указания на държавни и общински органи и организации или за целите на удовлетворяването на правни претенции, до окончателното приключване на съответните процедури.</p>
                </section>

                <section>
                    <h2>На кого предаваме Вашите лични данни?</h2>
                    <p>За да можем да поддържаме нашата база данни и да Ви изпращаме информационни, търговски и маркетингови съобщения, за които сте се абонирали, ние предаваме Вашите лични данни на Microsoft Ireland Operations Ltd, с адрес One Microsoft Place, South County Business Park Leopardstown Dublin 18, D18 P52.</p>
                    <p>Повече информация относно Политиката за поверителност на Microsoft Azure може да откриете на следния линк: <a href="https://privacy.microsoft.com/en-gb/privacystatement" target="_blank">https://privacy.microsoft.com/en-gb/privacystatement</a>.</p>
                    <p>Вашите лични данни не се предават на дружества, органи и организации извън Европейския съюз и Европейското икономическо пространство.</p>
                </section>

                <section>
                    <h2>Какви са Вашите права относно предоставените лични данни?</h2>
                    <p>При спазване на българското и европейското законодателство относно защитата на личните данни, включително ОРЗД, Вие можете да упражните следните права:</p>
                    <ul>
                        <li>Правото на достъп до личните данни, които „Пет Кънект“ ООД, чрез своите съдружници, обработва за Вас и да получите копие от тях;</li>
                        <li>Право да искате от „Пет Кънект“ ООД коригиране, в случай че установите неточности или необходимост от актуализация на личните Ви данни, като изпратите имейл на: <span>help@petconnect.global</span></li>
                        <li>Право да искате блокиране на личните Ви данни или ограничаване на обработката на лични данни, в определените от закона и ОРЗД случаи;</li>
                        <li>Право да искате заличаване, т.е. изтриване на личните Ви данни от базите данни на „Пет Кънект“ ООД, в случай че са налице условията за това;</li>
                        <li>Правото, когато пожелаете, да оттеглите даденото от Вас съгласие личните Ви данни да бъдат обработвани за целите, за които сте дали съгласие (получаване на информационни, търговски и маркетинг съобщения), като изпратите имейл на: <span>help@petconnect.global</span> или като върнете отговор на полученото търговско съобщения на Вашата електронна поща;</li>
                        <li>Право да отправите заявка за преносимост на личните Ви данни в структуриран, машинно-читаем формат, който е общоупотребяван;</li>
                        <li>Правото да отправите жалба или молба за защита на правата Ви пред Комисията за защита на личните данни („КЗЛД“), в случай че са налице предпоставките за това.</li>
                    </ul>
                    <p>Можете да упражните всички права по всяко време на обработване на личните Ви данни.</p>
                </section>

                <h1>Какво значи всяко от горните права?</h1>

                <section>    
                    <h3>Право на достъп до личните данни.</h3>
                    <p>Това право Ви дава възможност да получите информация относно данните, които идентифицират Администратора и неговите представители, целите на обработването на личните данни, получателите или категориите получатели, на които могат да бъдат разкрити данните, данни за задължителния или доброволния характер на предоставяне на данните и последиците от отказ за предоставянето им, както и информация за правото на достъп и правото на коригиране на събраните данни.</p>
                    <p>Данните не се предоставят, когато физическото лице, за което се отнасят, вече разполага с тях или в закон съществува изрична забрана за предоставянето им.</p>
                </section>

                <section>
                    <h3>Право на заличаване, достъп, коригиране, блокиране.</h3>
                    <p>Право по всяко време да поискате от администратора да заличи, коригира или блокира лични данни, обработването на които не отговаря на изискванията на ОРЗД и ЗЗЛД, както и право да поискате от Администратора да уведоми третите лица, на които са били разкрити личните данни за всяко заличаване, коригиране или блокиране, което е извършено, с изключение на случаите, когато това е невъзможно или е свързано с прекомерни усилия.</p>
                </section>

                <section>
                    <h3>Право на възражение</h3>
                    <p>Право на възражение пред Администратора срещу обработването на личните данни на физическото лице при наличие на законово основание за това, както и срещу обработването и разкриването на трети лица на Вашите личните данни за целите на директния маркетинг.</p>
                    <p>Вие имате право да бъде уведомени преди личните Ви данни да бъдат разкрити за пръв път на трети лица или използвани от тяхно име за целите на директния маркетинг, като имате право да възразите срещу такова разкриване или използване.</p>
                </section>

                <section>
                    <h3>Право на преносимост</h3>
                    <p>Когато обработването на лични данни става по автоматичен начин Вие имате правото да получавате отнасящите се до Вас лични данни, които сте предоставили на администратора, в структуриран, широко използван, пригоден за машинно четене и оперативно съвместим формат, и да ги предавате на друг администратор.</p>
                    <p>Това право следва да се прилага, когато субектът на данни е предоставил личните данни въз основа на собственото си съгласие или обработването е необходимо поради договорно задължение.</p>
                    <p>Правото не следва да се прилага, когато обработването се базира на правно основание, различно от съгласие или договор. Поради самото си естество това право не следва да бъде упражнявано по отношение на администратори, обработващи данни в изпълнение на обществените си задължения. Ето защо това право не следва да се прилага, когато обработването на личните данни е необходимо за спазване на правно задължение, на което е подчинен Администраторът, или за изпълнение на задача от обществен интерес, или при упражняване на официално правомощие, предоставено на Администратора</p>
                    <p>Правото на субекта на данни да предава или получава отнасящи се до него лични данни не поражда задължение за администраторите да възприемат или поддържат технически съвместими системи за обработване. Когато в определен пакет от лични данни е засегнат повече от един субект на данни, правото личните данни да бъдат получавани следва да не засяга правата и свободите на други субекти на данни в съответствие с приложимото право</p>
                </section>

                <section>
                    <h3>Право да отправите жалба или молба до КЗЛД</h3>
                    <p>В случай на нарушаване на правата Ви имате право да сезирате КЗЛД в 6-месечен срок от узнаване на нарушението, но не по-късно от 2 (две) години от извършването му. При нарушаване на правата Ви имате право да обжалвате действия и актове на Администратора по съдебен ред. Съдът не може да бъде сезиран, ако има висящо производство пред КЗЛД или решение на КЗЛД относно същото нарушение е обжалвано и няма влязло в сила решение на съда.</p>
                    
                    <div>
                        <p>Можете да се обърнете към КЗЛД по следните начини:</p>
                        <ul>
                            <li>Писмено на адрес: гр. София, Столична община, п.к. 1592, бул. „проф. Цветан Лазаров“ № 2;</li>
                            <li>Телефон за връзка: <span>02/91-53-519</span> или <span>02/91-53-555</span></li>
                            <li>По факс: <span>029153525</span> или</li>
                            <li>По имейл на електронна поща: <a href="mailto:kzld@cpdp.bg" target="_blank">kzld@cpdp.bg</a></li>
                        </ul>

                        <p>Интернет страницата на КЗЛД можете да намерите на: <a href="https://cpdp.bg/" target="_blank">www.cpdp.bg</a></p>
                    </div>
                </section>
                
                <h1>Промени в Уведомлението за поверителност</h1>

                <section>
                    
                    <p>Най-актуалното Уведомление за поверителност относно обработката на лични данни за целите на изпращането на информационни, търговски и маркетингови съобщения можете да намерите винаги на нашата интернет-страница.</p>
                    <p>Така ще Ви бъде предоставена възможност да преустановите използването на някои или всички услуги и/или да се възползвате от правата си, част от които са посочени изрично по-горе</p>
                    <p>Така ще Ви бъде предоставена възможност да преустановите използването на някои или всички услуги и/или да се възползвате от правата си, част от които са посочени изрично по-горе</p>
                    <p>Тези Уведомление за поверителност е утвърдено от съдружниците на „Пет Кънект“ ООД – в процес на учредяване и е в сила от 15.07.2024 г.</p>
                </section>
            </div>
            
            <div className="footer-cont">
                <Footer />
            </div>
            
        </div>
    )
}