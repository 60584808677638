import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

export default function TransitionAlerts() {
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ width: '100%', marginTop: "-2rem", marginBottom: "-3.5rem"}}>
      <Collapse in={open}>
        <Alert
          severity="error"
          variant="outlined"
          sx={{ mb: 2, fontSize: "1.8rem", border: "none"}}
        >
          Невалиден имейл или парола.
        </Alert>
      </Collapse>
    </Box>
  );
}