import React from 'react';
import { useRef, useState } from 'react';
import {  useSortable } from '@dnd-kit/sortable';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import {CSS} from "@dnd-kit/utilities";
import "./sortable.css"


const  Sortable = ({ id, images, setImages }: any) => {

    const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});
    const inputRef = useRef<any>(null);
    
    const [image, setImage] = useState<any>('');

    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    }

    const handleImageClick = () => {
        if(inputRef.current !== null) {
            inputRef.current.click();
        }
    }

    const handleImageChange = (event: any) => {
        const file = event.target.files[0];

        const myNextList = [...images];
        const newImage = myNextList.find(
          a => a.id == id
        );

        newImage.source = URL.createObjectURL(file);
        setImages(myNextList);
    }
    

    return (

        <div onClick={handleImageClick} key={id} ref={setNodeRef} {...attributes} style={ style } className="image">

            {images[id-1] && images[id-1].source !== '' ? (

                <div key={id} style={{zIndex: "100", position: "relative", display: "flex", width: "100%", height: "100%"}}>
                    <img src={images[id-1].source} style={{ width: "100%", height: "100%"}} />
                    {/* <img style={{ width: "5vw"}} src="blob:http://localhost:3000/2a8257ce-d323-4fb9-a4c6-5eb5daeabd30"></img> */}
                    <div className="uploader">
                        <input type="file" ref={inputRef}  onChange={handleImageChange} className="edit-image" />
                        
                        <div style={{position:"absolute", bottom: "0.2vw", left: "5vw", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "-1vw", marginLeft: "-0.4vw"}}>
                            <CameraAltOutlinedIcon sx={{fontSize: "2vw", color: "#fff"}}></CameraAltOutlinedIcon>
                            <p>Update</p>
                        </div>

                    </div>

                    <div className="drag-action-cont">
                            <div style={{position: "relative", height: "100%", display: "flex", alignItems: "center", right: "2.4vw", top:"0vw", cursor: "pointer", zIndex: "10", backgroundColor: "rgba(0,0,0,0.5)", borderTopRightRadius: "5px", borderBottomRightRadius: "5px"}}>
                                <DragIndicatorOutlinedIcon {...listeners} sx={{fontSize: "2.5vw", color: "#D9D9D9"}}></DragIndicatorOutlinedIcon>
                            </div>
                    </div>  
                </div>
            ): ( 
                <div style={{ backgroundColor: "#fff", opacity: "1", zIndex: "0", width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <AddAPhotoOutlinedIcon  sx={{ zIndex: "-1", fontSize: "5vw", color: "#D9D9D9", cursor: "pointer" }}></AddAPhotoOutlinedIcon>
                    <input  type="file" 
                            ref={inputRef} 
                            onChange={handleImageChange} 
                            style={{ display: "none"}} />
            
                </div>
            )}
            

            
        </div>      
    );
}

export default Sortable;
 

