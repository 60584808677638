import { useState, useEffect } from "react";
import "./login.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "../register/InputField";
import Header from "../headers/Header";

import DogAnimated from "../dogs/DogAnimated";
import TransitionalAlert from "./TransitionalAlert";
import { Box, Button, Typography, useTheme } from "@mui/material";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { BsTwitter } from "react-icons/bs";

import { createTheme, ThemeProvider } from '@mui/material/styles'
import { Link } from "react-router-dom";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";

import { RegisterSnackBar } from "../register/Snackbar";
import { usePageRefresh } from "../../../hooks/usePageRefresh";

export const Login = () => {

  const HOST_NAME = "http://localhost:8080";
  const realm = "myRealm";
  const client_id = "myclient";
  const client_secret = "d4nc3bgz3hC4jXOjBK6I9vqrSBEkmkUo";

  const URL = HOST_NAME + "/realms/" + realm + "/protocol/openid-connect/token";

  let access_token = "";

  const [snackbarOpen, setSnackBarOpen] = useState(() => {
    const source =
      localStorage.getItem("source") === "register" &&
      JSON.parse(localStorage.getItem("showSnackbar") ?? "false");
    return source;
  });

  const [loginFailed, setLoginFailed] = useState(false);
  const [formSubmited, setIsFormSubmitted] = useState(false);
  const { palette } = useTheme();

  const [subscribeForm, setSubscribeForm] = useState({
    pass: "",
    email: ""
  });

const [email, setEmail] = useState("");
const [emailError, setEmailError] = useState(false);


  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

/*   const initialValues = {
    email: "",
    password: "",
  }; */

 /*  const validationSchema = Yup.object({
    email: Yup.string().email("Невалиден имейл")
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log(values);

      let body = {
        "grant_type": "password",
        "client_id": client_id,
        "client_secret": client_secret,
        "username": values.email,
        "password": values.password
      };

      let params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      };

      const fetchData = async () => {

        const result = await fetch(URL, params);

        return result.json().then(json => {
          console.log(json);
        })
      }

      fetchData();

      console.log("Form submitted with values:", values);
    },
    initialTouched: {
      email: false,
      password: false,
    },
  }); */

 /*  const handleFormSubmit = () => {
    formik.handleSubmit();
    setIsFormSubmitted(true);
    setLoginFailed(false);
    setTimeout(() => {
      debugger;
      if (!Boolean(formik.errors.email)) {
      } else {
        if (formik.values.password === "123") {

        } else {
          setLoginFailed(true);
        }
      }
      setIsFormSubmitted(false);
    }, 3000);
  }; */

  usePageRefresh();

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    if (e.target.validity.valid) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
};

  const newTheme = (theme: any) => createTheme({
    ...theme,
    components: {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    color: "red",
                    fontFamily: "Ubuntu"
                }
            }
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              backgroundColor: "#f6f6f6",
              borderWidth: "3px",
              borderRadius: "6px"
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {
              backgroundColor: "#f6f6f6"
            }
          }
        },
        MuiInputAdornment: {
          styleOverrides: {
            root: {
              backgroundColor: "#fff",
              opacity: 1,
              transform: "scale(1.5)"
            }
          }
        }
    }
})

  return (
    <div>
      <section className="login-section">
        <div className="login-section__form">
          {/* <Header></Header> */}

         {/*  <div className="dog_container">
          </div> */}

          <div className="flex center margin-bottom">
            <h1>LOGIN</h1>
          </div>
          
          <DogAnimated />
  
          <div className="login-section__form__container">
           {/*  <InputField
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.email || false}
              error={formik.errors.email}
              className="credential-input"
              placeholder="Имейл"
              errorClassName="error-input"
            />
            <InputField
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password || false}
              error={formik.errors.password}
              className="credential-input"
              placeholder="Парола"
              type="password"
              errorClassName="error-input"
            /> */}

            <div className="form-inputs-container">
              <div className="form-inputs">
                  <FormControl className="form-input-field">
                      <ThemeProvider theme={newTheme}>
                          <TextField
                              required
                              id="email"
                              label="Имейл"
                              name="email"
                              value={email}
                              variant="outlined"
                              color="success"
                              sx={{ width: "100%", fontSize: "1.4rem", marginBottom: "1.2rem" }}
                              onChange={handleEmailChange}

                              helperText={emailError ? "Моля въведете валиден имейл адрес" : " "}
                              
                              InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailOutlineIcon />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                  <InputAdornment position="end">
                                      {(email.length > 0 && !emailError) && (
                                      <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                      )}
                                  </InputAdornment>
                                  ),
                                  type: "email"
                              }}
                          />
                      </ThemeProvider>
                  </FormControl>

                  <FormControl className="form-input-field">
                      <ThemeProvider theme={newTheme}>
                          <TextField
                              required
                              type="password"
                              id="password"
                              label="Парола"
                              name="pass"
                              value={subscribeForm.pass}
                              variant="outlined"
                              color="success"
                              sx={{ width: "100%", fontSize: "1.4rem", marginBottom: "1.2rem" }}
                              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setSubscribeForm((data) => ({
                                ...data,
                                [event.target.name]: event.target.value,
                                }));
                              }}

                              helperText={" "}

                              InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <VpnKeyIcon />
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                  <InputAdornment position="end">
                                      {subscribeForm.pass.length >= 3 && (
                                      <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                      )}
                                  </InputAdornment>
                                  ),
                              }}
                          />
                      </ThemeProvider>
                  </FormControl>
              </div>           
            </div>

            {loginFailed && (
              <TransitionalAlert></TransitionalAlert>
            )}

            <Typography
              sx={{
                textDecoration: "underline",
                textAlign: "right",
                color: palette?.neutral?.dark,
                fontFamily: "Ubuntu",
                marginLeft: "clamp(27vw, 27vw, 30vw)",
                marginTop: "-2rem",
                width: "fit-content",
                height: "auto",
                "&:hover": {
                  cursor: "pointer",
                  color: palette.primary.light,
                },
              }}
            >
              Забравена парола?
            </Typography>

            <Box className="form-buttons-container">
              <Button
                    className="form-button"
                    variant="contained"
                    color="success"
                    type="submit"
                    disabled={ subscribeForm.pass.length < 3 || email == ''}
                >
                    Влизане
              </Button> 

              <Link to="/register">
                <Typography
                  onClick={() => { }}
                  sx={{
                    textDecoration: "underline",
                    color: palette?.neutral?.dark,
                    fontFamily: "Ubuntu",
                    m: "1rem 0 0 0 ",
                    "&:hover": {
                      cursor: "pointer",
                      color: palette.primary.light,
                    },
                  }}
                >
                  Нямате акаунт? Регистрирайте се тук
                </Typography>
              </Link>

              <Typography
                sx={{
                  color: palette?.neutral?.dark,
                  fontSize: "2rem",
                  textAlign: "left",
                  marginLeft: "8.2rem",
                  marginBottom: "0.8rem",
                  marginTop: "2rem",
                }}
              >
                Или влезте със
              </Typography>

              {/**<div className={styles.separator}></div>*/}

              <div className="separator_lines">
                <div className="line"></div>
                <div className="line"></div>
              </div>

              <Box
                display="flex"
                justifyContent="start"
                gap="4rem"
                marginTop="1rem"
                marginLeft="4rem"
                flexWrap="wrap"
                sx={{
                  alignItems: "center",
                }}
              >
                <FacebookIcon
                  sx={{
                    color: "#1877F2",
                    fontSize: "4rem",
                    cursor: "pointer",
                    "&:active": {
                      transform: "scale(0.9)"
                    }
                  }}
                />
                <TwitterIcon
                  sx={{
                    color: "#1DA1F2",
                    fontSize: "4rem",
                    cursor: "pointer",
                    "&:active": {
                      transform: "scale(0.9)"
                    }
                  }}
                />
                <GoogleIcon
                  sx={{
                    fontSize: "4rem",
                    cursor: "pointer",
                    color: "#DB4437",
                    "&:active": {
                      transform: "scale(0.9)"
                    }
                  }}
                />
              </Box>
            </Box>
          </div>
          
        </div>

        <div className="side_panel">
        {/*   <RegisterSnackBar
            snackbarOpen={snackbarOpen}
            handleCloseSnackBar={handleCloseSnackbar}
          /> */}

        {/* <div className="dog_container">
          <DogAnimated></DogAnimated>
        </div> */}
        

        </div>

      </section>

      <section className="right_side">
      </section>
    </div>

  );
};
