import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from "@mui/material/FormControl";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { transform } from 'cypress/types/lodash';
import { createTheme, ThemeProvider } from '@mui/material/styles'


export default function BasicDatePicker() {

  const newTheme = (theme: any) => createTheme({
    ...theme,
    components: {
      MuiPickersDay: {
        styleOverrides: {
          root: {
            color: '#2e7d32',
            borderRadius: '50%',
            borderWidth: '1px',
            borderColor: '#2196f3',
            border: '1px solid',
            backgroundColor: '#fff',
            fontSize: 18,
            "&:hover": {
              backgroundColor: '#d4ffdc'
            }
          },
          today: {
            backgroundColor: '#a3ffb4'
          }
        }
      },
     
      MuiPickersCalendarHeader: {
        styleOverrides: {
          root: {
            color: '#65C466',
            borderRadius: '2px',
            borderWidth: '1px',
            backgroundColor: '#fff'
          },
          label: {
            color: '#2e7d32',
            fontSize: 18,
            fontWeight: 900
          },
          switchViewButton: {
            transform: 'scale(1.6)'
          }
        }
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            color: '#65C466',
            backgroundColor: '#fff',
            fontSize: 16,
            fontWeight: 500

          }
        }
      },
      MuiPickersMonth: {
        styleOverrides: {
          monthButton: {
            fontSize: 18
          }
        }
      },
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            fontSize: '16px'
          }
        }
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: '26px',
            '&:hover': {
              color: '#65C466'
            }
          }
        }
      }
    }
  })

  return (
    <FormControl sx={{width: '50%', zIndex: "10"}}>
        <LocalizationProvider  dateAdapter={AdapterDayjs}>
            <DemoContainer  components={['DatePicker']} sx={{ mt: "0.3vw", width: "100%"}} >
              <ThemeProvider theme={newTheme}>
                <DesktopDatePicker sx={{width: '100%'}}
                    
                      label="Дата на раждане"
                     
                      />
              </ThemeProvider>
               
            </DemoContainer>
        </LocalizationProvider>
    </FormControl>
    
  );
}