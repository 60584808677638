import List from "@mui/material/List";

import { Chat } from "./Chat";

import { useTypedSelectorMessage } from "../../../features/messagesSlice";
import { messagesStruct } from "../../../features/messagesSlice";
import { EmptyChatList } from "./EmptyChatList";

export const ChatList = () => {
  const messages = useTypedSelectorMessage((state) => state.messages.messages);

  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {messages.length > 0 ? (
        messages.slice(0,5).map((chat: messagesStruct, index) => {
          return <Chat {...chat} key={index} />;
        })
      ) : (
        <EmptyChatList />
      )}
    </List>
  );
};
