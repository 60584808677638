import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "10%",
    },
  },
};

const names = [
  'Черен',
  'Бял',
  'Бежов',
  'Кафяв',
  'Сив'
];

export default function MultipleSelectCheckmarks({ name ,setFormData }: any) {
  const [color, setColor] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof color>) => {
    const {
      target: { value },
    } = event;
    setColor(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    console.log(event);
    setFormData((state: any) => ({
      ...state,
      [event.target.name]: event.target.value,
    }));
  };

  return (
      <FormControl sx={{ width: "100%"}}>
        <InputLabel id="demo-multiple-checkbox-label">Цвят</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          name={ name }
          value={ color }
          onChange={ handleChange }
          input={<OutlinedInput label="Цвят" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={color.indexOf(name) > -1} sx={{ transform: "scale(1.3)"}}/>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
}