import "./editProfile.css";

import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { SimpleStepper } from "../../register/stepper/SimpleStepper";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from '@mui/material/Autocomplete';

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WestIcon from '@mui/icons-material/West';
import SaveIcon from '@mui/icons-material/Save';
import ErrorIcon from '@mui/icons-material/Error';
import EditIcon from '@mui/icons-material/Edit';
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';


export const EditProfile = ({showModal, setShowModal}: any): JSX.Element => {
    //DUMMY DATA
    const cities = [
        {city: 'Айтос',district: 'Бургас'},
        {city: 'Аксаково',district: 'Варна'},
        {city: 'Алфатар',district: 'Силистра'},
        {city: 'Антоново',district: 'Търговище'},
        {city: 'Априлци',district: 'Ловеч'},
        {city: 'Ардино',district: 'Кърджали'},
        {city: 'Асеновград',district: 'Пловдив'},
        {city: 'Ахелой',district: 'Бургас'},
        {city: 'Ахтопол',district: 'Бургас'},
        {city: 'Балчик',district: 'Добрич'},
        {city: 'Банкя',district: 'София град'},
        {city: 'Банско',district: 'Благоевград'},
        {city: 'Баня',district: 'Пловдив'},
        {city: 'Батак',district: 'Пазарджик'},
        {city: 'Батановци',district: 'Перник'},
        {city: 'Белене',district: 'Плевен'},
        {city: 'Белица',district: 'Благоевград'},
        {city: 'Белово',district: 'Пазарджик'},
        {city: 'Белоградчик',district: 'Видин'},
        {city: 'Белослав',district: 'Варна'},
        {city: 'Берковица',district: 'Монтана'},
        {city: 'Благоевград',district: 'Благоевград'},
        {city: 'Бобовдол',district: 'Кюстендил'},
        {city: 'Бобошево',district: 'Кюстендил'},
        {city: 'Божурище',district: 'Софийска'},
        {city: 'Бойчиновци',district: 'Монтана'},
        {city: 'Болярово',district: 'Ямбол'},
        {city: 'Борово',district: 'Русе'},
        {city: 'Ботевград',district: 'Софийска'},
        {city: 'Брацигово',district: 'Пазарджик'},
        {city: 'Брегово',district: 'Видин'},
        {city: 'Брезник',district: 'Перник'},
        {city: 'Брезово',district: 'Пловдив'},
        {city: 'Варна',district: 'Варна'},
        {city: 'Велико Търново',district: 'Велико Търново'},
        {city: 'Велинград',district: 'Пазарджик'},
        {city: 'Видин',district: 'Видин'},
        {city: 'Враца',district: 'Враца'},
        {city: 'Габрово',district: 'Габрово'},
        {city: 'Годеч',district: 'Софийска'},
        {city: 'Гоце Делчев',district: 'Благоевград'},
        {city: 'Гълъбово',district: 'Стара Загора'},
        {city: 'Девин',district: 'Смолян'},
        {city: 'Добрич',district: 'Добрич'},
        {city: 'Доспат',district: 'Смолян'},
        {city: 'Елин Пелин',district: 'Софийска'},
        {city: 'Елена',district: 'Велико Търново'},
        {city: 'Етрополе',district: 'Софийска'},
        {city: 'Златоград',district: 'Смолян'},
        {city: 'Златица',district: 'Софийска'},
        {city: 'Игнатиево',district: 'Варна'},
        {city: 'София',district: 'София град'},
    ];

    const options = cities.map((option) => {
        const firstLetter = option.city[0].toUpperCase();
            return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
            };
    });

    //-------------------------------------------------------------------------------------

    // PHONE CODES
    interface CountryType {
        code: string;
        label: string;
        phone: string;
        suggested?: boolean;
    }

    // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
    const countries: readonly CountryType[] = [     
    { code: 'AL', label: 'Албания', phone: '+355' },
    { code: 'AT', label: 'Австрия', phone: '+43' },
    { code: 'BA', label: 'Босна и Херцеговина', phone: '+387'},
    { code: 'BE', label: 'Белгия', phone: '+32' },
    { code: 'BG', label: 'България', phone: '+359' },
    { code: 'BY', label: 'Беларус', phone: '+375' },
    { code: 'CA', label: 'Канада',  phone: '+1', suggested: true,},
    { code: 'CH', label: 'Швейцария', phone: '+41' },
    { code: 'CY', label: 'Кипър', phone: '+357' },
    { code: 'CZ', label: 'Чехия', phone: '+420' },
    { code: 'DE', label: 'Германия', phone: '+49', suggested: true},
    { code: 'DK', label: 'Дания', phone: '+45' },
    { code: 'ES', label: 'Испания', phone: '+34' },
    { code: 'FI', label: 'Финландия', phone: '+358' },
    { code: 'FR', label: 'Франция', phone: '+33', suggested: true},
    { code: 'GB', label: 'Англия', phone: '+44' },
    { code: 'GR', label: 'Гърция', phone: '+30' },
    { code: 'HR', label: 'Хърватия', phone: '+385' },
    { code: 'HU', label: 'Унгария', phone: '+36' },
    { code: 'IE', label: 'Ирландия', phone: '+353' },
    { code: 'IT', label: 'Италия', phone: '+39' },
    { code: 'MK', label: 'Македония', phone: '+389',},
    { code: 'NL', label: 'Холандия', phone: '+31' },
    { code: 'NO', label: 'Норвегия', phone: '+47' },
    { code: 'PL', label: 'Полша', phone: '+48' },
    { code: 'PT', label: 'Португалия', phone: '+351' },
    { code: 'RO', label: 'Румъния', phone: '+40' },
    { code: 'RS', label: 'Сърбия', phone: '+381' },
    { code: 'RU', label: 'Русия', phone: '+7' },
    { code: 'SE', label: 'Швеция', phone: '+46' },
    { code: 'SI', label: 'Словения', phone: '+386' },
    { code: 'SK', label: 'Словакия', phone: '+421' },  
    { code: 'TR', label: 'Турция', phone: '+90' },
    { code: 'UA', label: 'Украйна', phone: '+380' },
    { code: 'US', label: 'Америка', phone: '+1', suggested: true },
    ];

    //--------------------------------------------------------------------------------------

    const [activeStep, setActiveStep] = useState(0);

    // ERRORS
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    const [profileInfo, setProfileInfo] = useState({
        email: ""
    });

    const [personalInfo, setPersonalInfo] = useState({
        firstName: "",
        lastName: "",
        city: "",
        code: "",
        phone: "",
        vetDocumentNumber: ""
    });

    const [companyInfo, setCompanyInfo] = useState({
        companyName: "",
        EIK: "",
        address: "",
    });

    const onChangeHandler = (e: SelectChangeEvent | React.ChangeEvent<HTMLInputElement>) => {
        setProfileInfo((state) => ({
          ...state,
          [e.target.name]: e.target.value,
        }));
    };

    const steps = [
    'Лични данни',
    'Лични данни 2'
    ];

    const containsOnlyNumbers = (str: string) => {
        return /^\d+$/.test(str);
        // return /[0-9]+/.test(str);
    };
    
    function validateEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const checkFormFields = () => {
        return profileInfo.email === "" || emailError || personalInfo.firstName === "" || personalInfo.lastName === "" || personalInfo.city === "" || personalInfo.phone === "";
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfileInfo((state) => ({
            ...state,
            [e.target.name]: e.target.value,
        }));

        if(validateEmail(e.target.value)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPersonalInfo((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));

        if(containsOnlyNumbers(e.target.value)) {
            setPhoneError(false);
        }else {
            setPhoneError(true);
        }
    };

    const handleDocumentNumber= (e: React.ChangeEvent<HTMLInputElement>) => {

        if(containsOnlyNumbers(e.target.value) || e.target.value === ''){
            if(personalInfo.vetDocumentNumber.length < 8 || (personalInfo.vetDocumentNumber.length === 8 && e.target.value.length < personalInfo.vetDocumentNumber.length)) {    
                setPersonalInfo((data) => ({
                    ...data,
                    [e.target.name]: e.target.value,
                }));    
            } 
        }        
    };

    const handleActiveStep = (direction: string) => {
        if(direction == "forward") {
            if(activeStep == 2) return;

            const newStep = activeStep + 1;
            setActiveStep(newStep);
        }
        else if (direction == "back") {
            if(activeStep == 0) return;

            const newStep = activeStep - 1;
            setActiveStep(newStep);
        }
    };

    const handleSubmit = async () => {
        const registerUserURL = "http://localhost:8080/admin/realms/myrealm/users";
    };

    const textFieldTheme = createTheme({
        components: {
            MuiFormHelperText: {
             styleOverrides: {
              root: {
                color: 'red',
                margin: '5px auto'
              }
            }
            },
            MuiInputLabel: {
            styleOverrides: {
                shrink: {
                  fontSize: "1.1rem"
                }
          }
            }
        }
    })
 
    return (
        <div className="edit-form-container">

            <div className="edit-form-title">
                <EditIcon sx={{ fontSize: "2.5rem"}} />
                <p>Редактирайте своя профил</p>
            </div>

            <section>
                {/* Име */}
                <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                    <TextField
                        id="firstName"
                        label="Име"
                        name="firstName"
                        value={personalInfo.firstName}
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%", fontSize: "1rem"}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPersonalInfo((data) => ({
                                ...data,
                                [event.target.name]: event.target.value,
                            }));
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {personalInfo.firstName.length >= 3 && (
                                        <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                    )}
                                </InputAdornment>),
                        }}
                    />
                </FormControl>

                {/* Фамилия */}
                <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                    <TextField
                        id="lastName"
                        label="Фамилия"
                        name="lastName"
                        value={personalInfo.lastName}
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%", fontSize: "1.4rem"}}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPersonalInfo((data) => ({
                                ...data,
                                [event.target.name]: event.target.value,
                            }));
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {personalInfo.lastName.length >= 3 && (
                                        <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                    )}
                                </InputAdornment>),
                        }}
                    />
                </FormControl>

                {/* Имейл */}
                <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                    <ThemeProvider theme={textFieldTheme}>
                        <TextField
                            id="username"
                            label="Имейл"
                            name="email"
                            autoComplete='off'
                            value={profileInfo.email}
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", fontSize: ""}}
                            onChange={ handleEmailChange }
                            InputProps={{
                                /* startAdornment: (
                                    <InputAdornment position="start">
                                        {(profileInfo.email.length < 3 && !emailError) && (
                                            <MailOutlineIcon color="success" sx={{ color: "grey", fontSize: "2.5rem", mr: "0.6rem" }}/>
                                        )}
                                        {(emailError) && (
                                            <MailOutlineIcon color="success" sx={{ color: "#A51414", fontSize: "2.5rem", mr: "0.6rem" }}/>
                                        )}
                                        {(profileInfo.email.length > 3 && !emailError) && (
                                            <MailOutlineIcon color="success" sx={{ color: "#65C466", fontSize: "2.5rem", mr: "0.6rem" }}/>
                                        )}
                                    </InputAdornment>), */
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(profileInfo.email.length >= 3 && !emailError) && (
                                            <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                        )}
                                    </InputAdornment>),
                                
                            }}
                        />
                    </ ThemeProvider>

                    { emailError && (
                        <div className="errorContainer">
                            <ErrorIcon sx={{ color: "#A51414", fontSize: "2rem" }}/>
                            <p className="errorMessage">Моля въведете валиден имейл адрес</p>
                        </div>
                        
                    )}
                </FormControl>

                {/* Населено място */}
                <Autocomplete
                    options={options.sort((a, b) => -b.district.localeCompare(a.district))}
                    groupBy={(option) => option.district}
                    getOptionLabel={(option) => option.city}
                    inputValue={personalInfo.city}
                    onChange={(event, newValue) => {
                        console.log(newValue);

                        let tempValue = newValue === null ? {
                            city: "",
                            district: "",
                            firstLetter: ""
                        }: newValue;
                        
                        setPersonalInfo((data) => ({
                            ...data,
                            ["city"]: tempValue.city
                        }));
                        
                    }}
                    sx={{ width: "100%", fontSize: "1.4rem", marginTop: "1.5vw"}}
                    renderInput={(params) => <TextField 
                        {...params}
                        id="city"
                        label="Населено място" 
                        name="city"
                        variant="outlined"
                        color="success"
                        value={personalInfo.city}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {  
                            setPersonalInfo((data) => ({
                                ...data,
                                [event.target.name]: event.target.value,
                            }));
                        }} 
                    />}
                />
                    
                {/* Телефонен номер */}
                <FormControl sx={{ width: "100%", marginTop: "1.5vw", display: "flex", flexDirection: "row"}}>
                    {/* КОД на държавата */}
                    <Autocomplete
                        id="country-select-demo"
                        sx={{ width: "14rem" }}
                        options={countries}
                        autoHighlight
                        inputValue={personalInfo.code}
                        onChange={(event, newValue) => {

                            let tempValue = newValue === null ? {
                                code: "",
                                label: "",
                                phone: ""
                            }: newValue;
                            
                            setPersonalInfo((data) => ({
                                ...data,
                                ["code"]: tempValue.phone
                            }));
                            
                        }}
                        getOptionLabel={(option) => option.label}
                        renderOption={(props: any, option) => {
                            const { key, ...optionProps } = props;
                            return (
                                <Box
                                    key={key}
                                    component="li"
                                    sx={{ '& > img': { mr: 1, flexShrink: 0 }, fontSize: "1.5rem" }}
                                    {...optionProps}
                                >
                                    <img
                                    loading="lazy"
                                    width="16"
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    alt=""
                                    />
                                    <span style={{ fontSize: "1.2rem" }}>{option.phone}</span>
                                </Box>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Код"
                                name="code"
                                variant="outlined"
                                color="success"
                                sx={{ fontSize: "0.8rem" }}
                                value={personalInfo.code}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    console.log("CODE: " + personalInfo.code);
                                    
                                    setPersonalInfo((data) => ({
                                        ...data,
                                        [event.target.name]: event.target.value,
                                    }));
                                }} 
                            />
                        )}
                    />

                    {/* Номер */}
                    <TextField
                        id="phone"
                        label="Телефонен номер"
                        name="phone"
                        value={personalInfo.phone}
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%", fontSize: "1.4rem"}}
                        onChange={ handlePhoneNumber }
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {(personalInfo.phone.length == 9 && !phoneError) && (
                                        <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                    )}
                                </InputAdornment>),
                        }}
                    />
                </FormControl>     
            </section>
            
            {/* BUTTONS */}
            <div className="edit-buttons-container">
                <Button
                    sx={{ width: "12rem", padding: "0.9rem 3.4rem", fontSize: "1.4rem", backgroundColor: "#A51414", "&:hover": {backgroundColor: "#8D1111", transform: "scale(1.03)"} }}
                    variant="contained"
                    color="success"
                    onClick={() => {
                        setShowModal(false);
                    }}
                >
                    <DisabledByDefaultOutlinedIcon sx={{ fontSize: "2rem", mr: "0.5vw"}}></DisabledByDefaultOutlinedIcon>
                    ЗАТВОРИ
                </Button>   

                <Button
                    sx={{ width: "12rem", padding: "0.9rem 3.4rem", fontSize: "1.4rem", backgroundColor: "#65C466", "&:hover": {backgroundColor: "#509C51", transform: "scale(1.03)"} }}
                    variant="contained"
                    color="success"
                    disabled={ checkFormFields() }
                    onClick={ handleSubmit }
                >
                    <SaveIcon sx={{ fontSize: "2rem", mr: "0.5vw"}}></SaveIcon>
                    ЗАПАЗИ
                </Button>                  
            </div>
        </div>
    );
};
