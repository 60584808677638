import "./petImagesModal.css";
import React from 'react';
import { useState}  from 'react';
import { Link } from 'react-router-dom';

import { motion, AnimatePresence } from 'framer-motion';

import Button from "@mui/material/Button";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';

import { closestCorners, DndContext } from "@dnd-kit/core";
import { horizontalListSortingStrategy, SortableContext, useSortable, arrayMove } from '@dnd-kit/sortable';

import Sortable  from "../common/profile/user/Sortable";

const backdrop = {
    visible: { opacity: 1},
    hidden: { opacity: 0},
}

const modal = {
    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "10vh",
        opacity: 1,
        transition: {
            delay: 0.5
        }
    }
}

const  PetImagesModal = ({ showModal, setShowModal }: any) => {
    
    const [imageSlots, setImageSlots] = useState([
        {id: 1},
        {id: 2},
        {id: 3},
        {id: 4},
        {id: 5},
        {id: 6},
        {id: 7},
        {id: 8}
    ]);

    const [images, setImages] = useState([
        {
            id: 1,
            source: ''
        },
        {
            id: 2,
            source: ''
        },
        {
            id: 3,
            source: ''
        },
        {
            id: 4,
            source: ''
        },
        {
            id: 5,
            source: ''
        },
        {
            id: 6,
            source: ''
        },
        {
            id: 7,
            source: ''
        },
        {
            id: 8,
            source: ''
    }])

    const getSlotPosition = (id: number) => {
        return imageSlots.findIndex(slot => slot.id === id);
    }

    const handleDragEnd = (event: any) => {
        const {active, over} = event;

        if (active.id === over.id) return;

        setImageSlots(imageSlots => {
            const originalPos = getSlotPosition(active.id);
            const newPos = getSlotPosition(over.id);

            return arrayMove(imageSlots, originalPos, newPos);
        })
    }

    return (
        <AnimatePresence mode='wait'>
            { showModal && (
               <motion.div className="modal_backdrop" variants={ backdrop } initial="hidden" animate="visible" exit="hidden">

                    <motion.div className="modal_main" variants={ modal }>
                        <div className="modal_container">
                            <p>Качете до 8 снимки на вашия домашен любимец</p>
                            <div className="images_container" >
                                <DndContext onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                    {imageSlots.map( slot => {
                                    return   <SortableContext items={imageSlots} strategy={horizontalListSortingStrategy}>
                                                <Sortable id={slot.id} images={ images } setImages={ setImages }></Sortable>
                                            </SortableContext>     
                                    })}
                                </DndContext>
                            </div>
                            
                            <div className="btn-container">
                                <Button
                                    sx={{ width: "22%", padding: "1rem 0.5rem", fontSize: "1.5rem", backgroundColor: "#65C466", "&:hover": {backgroundColor: "#509C51", transform: "scale(1.03)"}}}
                                    variant="contained"
                                    color="success"
                                    type="submit"
                                    >
                                        <SaveOutlinedIcon sx={{ fontSize: "3rem", mr: "0.5vw"}}></SaveOutlinedIcon>
                                        Запази
                                </Button>    
                            </div>
                            

                        </div>
                    </motion.div>

               </motion.div> 
            )}
        </AnimatePresence>
    );
}

export default PetImagesModal;
 

