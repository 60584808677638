import React, { useCallback } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useDispatch } from "react-redux";
import { markNotificationAsRead } from "../../../features/notificationsSlice";
import { useTheme } from "@mui/material";

interface NotificationProps {
  name: string;
  img: string;
  text: {
    content: string;
    isRead: boolean;
  };
  type?: string;
}

export const Notification: React.FC<NotificationProps> = ({
  name,
  img,
  text,
  type,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const determineMessageColor = useCallback(() => {
    return {
      color:
        theme.palette.mode === "light"
          ? text.isRead
            ? theme.palette.grey[700]
            : theme.palette.common.black
          : text.isRead
          ? theme.palette.grey[400]
          : theme.palette.common.white,
      fontWeight: text.isRead ? "" : "bold",
    };
  }, [theme.palette.mode, text.isRead]);

  return (
    <ListItem
      sx={{
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
        },
        borderRadius: "10px",
      }}
      onClick={() => dispatch(markNotificationAsRead({ name, img, text }))}
      alignItems="flex-start"
      secondaryAction={
        text.isRead ? "" : <FiberManualRecordIcon color="primary" />
      }
    >
      <ListItemAvatar sx={{ position: "relative" }}>
        <Avatar
          alt="Remy Sharp"
          src={img}
          sx={{ width: "56px", height: "56px" }}
        />
      </ListItemAvatar>
      <ListItemText
        primary={name}
        secondary={
          <>
            <Typography
              sx={{
                display: "inline",
                color: determineMessageColor().color,
                fontWeight: determineMessageColor().fontWeight,
              }}
              component="span"
              variant="body2"
            >
              {text.content.slice(0, 50) + "..."}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};
