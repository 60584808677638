import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import { RootState } from "../app/store";

interface storeState {
  notifications: notificationsStruct[];
  unreadNotifications: number;
}

interface textContent {
  content: string;
  isRead: boolean;
}

export interface notificationsStruct {
  img: string;
  name: string;
  text: textContent;
  type? : string
}

const initialState = {
  notifications: [] as notificationsStruct[],
  unreadNotifications: 0,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<notificationsStruct[]>) => {
      state.notifications = action.payload.sort(
        (a: notificationsStruct, b: notificationsStruct) => {
          if (a.text.isRead && !b.text.isRead) {
            return 1;
          } else if (!a.text.isRead && b.text.isRead) {
            return -1;
          }
          return 0;
        }
      );

      state.unreadNotifications = state.notifications.filter(
        (el: notificationsStruct) => el.text.isRead === false
      ).length;
    },
    markNotificationAsRead: (state, action: PayloadAction<notificationsStruct>) => {
      const notificationId = action.payload.name;
      const notificationToMark = state.notifications.find(
        (notification) => notification.name === notificationId
      );

      if (notificationToMark) {
        notificationToMark.text.isRead = true;
        state.unreadNotifications = state.notifications.filter(
          (el: notificationsStruct) => el.text.isRead === false
        ).length;
      }
    },
  },
});

export const { setNotifications, markNotificationAsRead } = notificationsSlice.actions;
export default notificationsSlice.reducer;

export const useTypedSelectorNotification: TypedUseSelectorHook<RootState> = useSelector;
