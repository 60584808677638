import React, { useState } from "react";
import "./createPetProfile.css";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";

import { lightGreen } from "@mui/material/colors";

import BasicDatePicker from "./BasicDatePicker";
import MultiSelect from "./MultiSelect";
import ComboBox from "./ComboBox";
import FileUpload from "../common/profile/user/FileUpload";
import PetImagesModal from "./PetImagesModal";
import { SimpleStepper } from "../common/register/stepper/SimpleStepper";


import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import ArrowCircleRightOutlinedIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

import cat_animated from "../../images/cat_2.gif";
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';

export const CreatePetProfile = ({ showModal, setShowModal, pets, setPets }: any) => {

const [showImagesModal, setShowImagesModal] = useState(false);
const [activeStep, setActiveStep] = useState(0);

const handleImagesModal = function() {
  setShowImagesModal(true);
}


const steps = [
    'Лични данни',
    'Лични данни 2',
    'Снимки'
];

const handleActiveStep = (direction: string) => {
    if(direction == "forward") {
        if(activeStep == 2) return;

        const newStep = activeStep + 1;
        setActiveStep(newStep);
    }
    else if (direction == "back") {
        if(activeStep == 0) return;

        const newStep = activeStep - 1;
        setActiveStep(newStep);
    }
};

  
  const [formData, setFormData] = useState({
    dogName: "",
    dogBreed: "",
    dogWeight: "",
    dogHeight: "",
    city: "",
    region: "",
    dogSex: "",
    dogAge: "",
    dogColor: "",
    dogBirthDate: "",
    profileImage: "",
    documentImage: "",
    vaccinated: false,
    originalBreed: false
  });

  const [profileType, setProfileType] = useState("cat");

  const [errors, setErrors] = useState({
    nameError: false,
    weightError: "",
    ageError: "",
    imageError: "",
  });

  const [vaccinated, setVaccinated] = useState(false);
  const [originalBreed, setOriginalBreed] = useState(false);

  const [dogImage, setDogImage] = useState("");
  const [documentImage, setDocumentImage] = useState("");

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const newPet = {
      name: formData.dogName,
      breed: formData.dogBreed,
      region: formData.region,
      city: formData.city,
      sex: formData.dogSex,
      color: formData.dogColor,
      weight: formData.dogWeight,
      height: formData.dogHeight,
      birthday: "",
      vaccinated: vaccinated,
      original_breed: originalBreed,
      image: dogImage,
      document_image: documentImage
    }
    
    setPets([...pets, newPet]);
    setShowModal(false);
  };

  const containsOnlyNumbers = (str: string) => {
    return /^\d+$/.test(str);
  };

  const containsOnlyLetters = (str: string) => {
    return /^[a-zA-Z-]+$/.test(str);
  };

  const onChangeHandler = (
    e: SelectChangeEvent | React.ChangeEvent<HTMLInputElement>
  ) => {

    console.log(e);
    setFormData((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onRegionChange = (
    e:  SelectChangeEvent | React.ChangeEvent<HTMLInputElement> | React.PointerEvent<HTMLCollection>
  ) => {

    if(e.target !== null) {

      const target = e.target;
      setFormData((state) => ({
        ...state,
        ["region"]: (target as HTMLElement).innerHTML
      }));
    }
  }; 

  const onCityChange = (
    e:  SelectChangeEvent | React.ChangeEvent<HTMLInputElement> | React.PointerEvent<HTMLCollection>
  ) => {

    if(e.target !== null) {

      const target = e.target;
      setFormData((state) => ({
        ...state,
        ["city"]: (target as HTMLElement).innerHTML
      }));
    }
  }; 

  const onKeyDownHandlerNumbers = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (!e.currentTarget.value && e.key == "0") {
      e.preventDefault();
    }
    if (!containsOnlyNumbers(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  const onKeyDownHandlerLetters = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    /* if (!containsOnlyLetters(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    } */
  };

  const profile_upload_styles = {
    fontSize: "6.4vw", 
    color: "#7a7a83", 
    cursor: "pointer",
    "&:hover": { color: "#65C466" }
  }

  const breed_upload_styles = {
    fontSize: "5.4vw", 
    color: "#7a7a83", 
    cursor: "pointer",
    "&:hover": { color: "#65C466" }
  }

  let region = {
    label: 'Област',
    value: '',
    options: ['София', 'Пловдив', 'Варна', 'Бургас', 'Търново', 'Русе', 'Враца', 'Панагюрище', 'Видин']
  }

  let city = {
    label: 'Град',
    value: '',
    options: ['София', 'Пловдив', 'Варна', 'Бургас', 'Търново', 'Русе', 'Враца', 'Панагюрище', 'Видин']
  }

  const checkFormFields = () => {
    return formData.dogBreed === '' || formData.dogHeight === '' || formData.dogWeight === '' || formData.dogSex === '' || formData.dogName === '' || formData.dogColor == '' || dogImage === '' || documentImage == '';
  }

  const handleTypeChange = () => {
    if(profileType == 'dog') {
      setProfileType('cat');
    }
    else if (profileType == 'cat') {
      setProfileType('dog');
    }
  }

  return (
    <>
        <div className="pet-info-section-container">
            
            <PetImagesModal showModal={showImagesModal} setShowModal={setShowImagesModal}></PetImagesModal>
            <div className="form-card">
                    
                <div style={{ textAlign: "center", fontFamily: "Ubuntu", fontSize: "2rem"}}>
                    <p>Създайте профил за своя любимец</p>
                </div>

                {/* STEPPER */}
                <div style={{ marginTop: "5rem", marginBottom: "1rem" }}>
                    <SimpleStepper steps={steps} activeStep={activeStep}></SimpleStepper>
                </div>

                {activeStep == 0 && (
                    <section>
                        {/* PROFILE TYPE SELECTOR */}
                        <div className="animated-dog-cont" style={{ }}>

                            {profileType === "dog" ? (
                                <div style={{ marginBottom: "-2vw" }}>
                                {/* <DogAnimated2></DogAnimated2>*/} </div>
                                ): (
                                    <img src={cat_animated} style={{ width: "28vw", height: "28vw", transform: "scale(1.1)", position: "fixed", top: "-2rem", right: "35%", marginBottom: "2vw"}}/>
                                )}

                                <div className="arrows-container">
                                    <ArrowCircleLeftOutlinedIcon onClick={handleTypeChange} sx={{ fontSize: '2vw', cursor: 'pointer', zIndex: "10", "&:hover": { color: "#65C466" }}} ></ArrowCircleLeftOutlinedIcon>
                                    <p className="arrow_text">{ profileType =="dog" ? "Кучешки": "Котешки" }</p>
                                    <ArrowCircleRightOutlinedIcon onClick={handleTypeChange} sx={{ fontSize: '2vw', cursor: 'pointer', zIndex: "10", "&:hover": { color: "#65C466" }}} ></ArrowCircleRightOutlinedIcon>
                                </div>
                        </div>

                        <form onSubmit={ handleSubmit }>

                            {/* PICTURE -- PROFILE TYPE -- NAME -- BREED -- COLOR */}
                            <div className="dog-main-info_cont">
                                <div className="name-breed_container">
                                    <FormControl sx={{ width: "100%"}}>
                                    <TextField
                                    id="username"
                                    label="Име"
                                    name="dogName"
                                    value={formData.dogName}
                                    variant="outlined"
                                    color="success"
                                    sx={{ width: "100%", fontSize: "1.4rem", marginBottom: "1.2rem" }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFormData((data) => ({
                                        ...data,
                                        [event.target.name]: event.target.value,
                                        }));
                                    }}
                                    onKeyDown={onKeyDownHandlerLetters}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            {formData.dogName.length >= 3 && (
                                            <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                            )}
                                        </InputAdornment>
                                        ),
                                    }}
                                    />
                                    </FormControl>
                                    
                                    <div className="breed-color_cont">
                                    <FormControl sx={{ width: "100%"}}>
                                        <InputLabel>Порода</InputLabel>
                                        <Select
                                        name="dogBreed"
                                        value={ formData.dogBreed }
                                        onChange={ onChangeHandler }
                                        label="Порода"
                                        color="success"
                                        >
                                        <MenuItem value={"Голдън Ретривър"}>Голдън Ретривър</MenuItem>
                                        <MenuItem value={"Немска Овчарка"}>Немска Овчарка</MenuItem>
                                        <MenuItem value={"Хъски"}>Хъски</MenuItem>
                                        <MenuItem value={"Йоркшерски Териер"}>Йоркшерски Териер</MenuItem>
                                        <MenuItem value={"Мопс"}>Мопс</MenuItem>
                                        <MenuItem value={"Ротвайлер"}>Ротвайлер</MenuItem>
                                        <MenuItem value={"Питбул"}>Питбул</MenuItem>
                                        <MenuItem value={"Пудел"}>Пудел</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <MultiSelect name={ "dogColor" } setFormData={ setFormData }></MultiSelect>
                                    </div>
                                    
                                </div>
                            </div>

                            {/* SEX -- WEIGHT -- HEIGHT -- DATE */}
                            <div className="dog-details_cont">
                                <FormControl  sx={{ width: "50%", mt: 1.4 }}>
                                    <TextField
                                    error={formData.dogWeight.length >= 4 ? true : false}
                                    className="weightInput"
                                    type="text"
                                    color="success"
                                    name="dogWeight"
                                    variant="outlined"
                                    label="Килограми"
                                    value={formData.dogWeight}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            {formData.dogWeight.length <= 3 &&
                                            Number(formData.dogWeight) !== 0 && (
                                                <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                            )}
                                        </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFormData((data) => ({
                                        ...data,
                                        [event.target.name]: event.target.value,
                                        }));
                                    }}
                                    onKeyDown={onKeyDownHandlerNumbers}
                                    />
                                </FormControl>

                                <FormControl  sx={{ width: "50%", mt: 1.4 }}>
                                    <TextField
                                    error={formData.dogHeight.length >= 4 ? true : false}
                                    className="weightInput"
                                    type="text"
                                    color="success"
                                    name="dogHeight"
                                    variant="outlined"
                                    label="Височина"
                                    value={formData.dogHeight}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            {formData.dogHeight.length <= 3 &&
                                            Number(formData.dogHeight) !== 0 && (
                                                <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                            )}
                                        </InputAdornment>
                                        ),
                                    }}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setFormData((data) => ({
                                        ...data,
                                        [event.target.name]: event.target.value,
                                        }));
                                    }}
                                    onKeyDown={onKeyDownHandlerNumbers}
                                    />
                                </FormControl>

                            </div>

                            <div>
                                <FormControl sx={{ width: "50%", mt: 1.4}}>
                                    <InputLabel>Пол</InputLabel>
                                    <Select
                                        name="dogSex"
                                        value={formData.dogSex}
                                        onChange={onChangeHandler}
                                        label="Пол"
                                        color="success"
                                    >
                                        <MenuItem value={"Мъжко"}>Мъжко</MenuItem>
                                        <MenuItem value={"Женско"}>Женско</MenuItem>
                                    </Select>
                                </FormControl>

                                <BasicDatePicker ></BasicDatePicker>
                            </div>
                        </form>
                    </section>
                )}

                {activeStep == 1 && (
                    <section style={{ marginBottom: "3rem" }}>
                        {/* REGION -- CITY */}  
                        <div className="city-region-cont">
                            <ComboBox label={ region.label } 
                                        value={ formData.region } 
                                        options={ region.options }
                                        onChange={ onRegionChange } 
                                        >
                            </ComboBox> 

                            <ComboBox label={ city.label } 
                                        value={ formData.city } 
                                        options={ city.options } 
                                        onChange={ onCityChange }>
                            </ComboBox> 
                        </div>

                        {/* DOCUMENT -- CHECKBOXES */}
                        <div className="pet-breed-checkboxes_cont">
                            {documentImage && typeof documentImage !== 'string' ? (
                                    <img  style={{ width: "100%", height: "15vw", borderRadius: "25px"}} 
                                        src={URL.createObjectURL(documentImage)}>
                                    </img>
                                ): (
                                <div className="pet-originalBreed_cont">
                                    <p>Добави снимка на документ за родословие</p>
                                    <div className="upload-breed_cont">
                                        <FileUpload styles={ breed_upload_styles } image={ documentImage } setImage={ setDocumentImage }></FileUpload>
                                    </div>
                                </div>   
                            )} 
                        </div>

                        {/* CHECKBOXES */}
                        <div className="pet-checkboxes">
                            <FormControl fullWidth sx={{ mt: 2 }}>
                            <div>
                                <Checkbox
                                    value={vaccinated}
                                    name="isVaccinated"
                                    onChange={(e) => setVaccinated((state) => !state)}
                                    checked={vaccinated}
                                    sx={{
                                        transform: "scale(1.5)",
                                        //color: '#28b485',
                                        "&:hover": {
                                        color: '#65C466'
                                        },
                                        "&.Mui-checked": {
                                        color: '#65C466',
                                        },
                                    }}
                                />
                                <span className="vaccinated">Напълно ваксинирано</span>
                            </div>
                            </FormControl>

                            <FormControl fullWidth sx={{ mt: 2, ml: "-12rem" }}>
                            <div>
                                <Checkbox
                                    value={originalBreed}
                                    name="isOriginalBreed"
                                    
                                    onChange={(e) => setOriginalBreed((state) => !state)}
                                    checked={originalBreed}
                                    sx={{
                                        transform: "scale(1.5)",
                                        //color: '#28b485',
                                        "&:hover": {
                                        color: '#65C466'
                                        },
                                        "&.Mui-checked": {
                                        color: '#65C466',
                                        },
                                    }}
                                />
                                <span className="originalBreed">Потвърдено родословие</span>
                            </div>
                            </FormControl>  
                                
                        </div>  
                    </section>
                )}

                {activeStep == 2 && (
                    <div>

                    </div>
                )}

                {/* FORM BUTTONS */}      
                <div style={{ textAlign: "left", marginTop: "1vw", marginLeft: "0rem"}}>
                    <Button
                        sx={{ transition: "all 0.5s", width: "22%", padding: "0.8rem 0.5rem", fontSize: "1.5rem", backgroundColor: "#A51414", "&:hover": {backgroundColor: "#8D1111", transform: "scale(1.03)"} }}
                        variant="contained"
                        color="success"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        <DisabledByDefaultOutlinedIcon sx={{ fontSize: "3rem", mr: "0.5vw"}}></DisabledByDefaultOutlinedIcon>
                        Затвори
                    </Button>

                    {activeStep == 0 && (
                        <Button
                            sx={{ transition: "all 0.5s", width: "22%", ml: "1vw", padding: "0.8rem 0.5rem", fontSize: "1.5rem", backgroundColor: "#65C466", "&:hover": {backgroundColor: "+509C51", transform: "scale(1.03)"} }}
                            variant="contained"
                            color="success"
                            onClick={ () => { 
                                handleActiveStep("forward"); 
                            }}
                        >
                            <EastIcon sx={{ fontSize: "3rem", mr: "0.5vw"}}></EastIcon>
                            НАПРЕД
                        </Button>
                    )}

                    {activeStep != 0 && (
                        <Button
                            sx={{ width: "22%", padding: "0.8rem 0.5rem", fontSize: "1.5rem", ml: "1vw", backgroundColor: "#65C466", "&:hover": {backgroundColor: "#509C51", transform: "scale(1.03)"}}}
                            variant="contained"
                            color="success"
                            type="submit"
                            disabled={true}
                        >
                            <SaveOutlinedIcon sx={{ fontSize: "3rem", mr: "0.5vw"}}></SaveOutlinedIcon>
                            Запази
                        </Button>
                    )}
                            
                </div>
            </div>
        </div>
    </>
  );
};
