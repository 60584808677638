import { RootState } from "../app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";

const initialState = {
  mode: "light",
};

interface themeState {
  mode: 'light' | 'dark';
}

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
  },
});


export const { setMode } = themeSlice.actions;
export default themeSlice.reducer;
export const useTypedSelectorTheme: TypedUseSelectorHook<RootState> = useSelector;
