import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: "Niki Bonkov",
  token: null,
};

interface storeState{
  user: string,
  token: string,
};


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    setLogout: (state) => {
      state.user = null as any;
      state.token = null;
    },

  },
});

export const {setLogin, setLogout} = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state: storeState) => state.user;