import { useTypedSelectorNotification } from "../../../features/notificationsSlice";
import { notificationsStruct } from "../../../features/notificationsSlice";
import List from "@mui/material/List";
import { Notification } from "./Notification";
import { EmptyNotificationList } from "./EmptyNotificationList";

export const NotificationList = () => {
  const notifications = useTypedSelectorNotification(
    (state) => state.notifications.notifications
  );
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {notifications.length > 0 ? (
        notifications.slice(0,5).map((chat: notificationsStruct, index: number) => {
          return <Notification {...chat} key={index} />;
        })
      ) : (
        <EmptyNotificationList />
      )}
    </List>
  );
};
