import React, { useEffect, useRef, useState } from 'react';
import { motion, useInView, useAnimation } from "framer-motion";
import useMousePosition from '../../hooks/useMousePosition';

interface Props {
    children: JSX.Element,
    height?: "max-content" | "120vh" | "170vh",
    once: true | false,
    positionOffset: 530 | 550
}

export const Reveal = ({ children, height = "120vh", once = true, positionOffset}: Props) => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    if(isMobile) {
        height = "max-content";
    }

    const mousePosition = useMousePosition();

    const ref = useRef<HTMLInputElement>(null);
    const inInView = useInView(ref, { });

    const mainControls = useAnimation();

    useEffect(() => {
        if (inInView) {
            mainControls.start('visible'); 
        }

       else if(!once) {

            if(ref.current !== null && ref.current !== undefined) {
                const rect = ref.current.getBoundingClientRect();
                let posY = rect?.top - mousePosition?.y;
/* 
                if(positionOffset != 530) {
                    console.log("MOUSE Y: " + mousePosition?.y);
                    console.log("ELEMENT Y: " + rect?.top);
                    console.log("DIFF: " + posY);
                } */

                if(posY > 0 && posY <= positionOffset)
                    mainControls.start('hidden'); 
            }
        }
    }, [inInView]);

    return (
        <div ref={ref} style={{ transform: "translateY(50px)" }}>
            <motion.div
                variants={{
                    hidden: { opacity: 0, width: 0, height: 0, transform: "translateY(50px) scale(0)" },
                    visible: { opacity: 1, width: "100%", height: height, transform: "translateY(-50px) scale(1)"}
                }}

                initial="hidden"
                animate={mainControls}
                transition={{ duration: 0.5 }}
            >
                {children}
            </motion.div>
        </div>
    );
};