import * as React from 'react';
import { useRef, useState } from 'react';
import "./fileUpdate.css";

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

export default function FileUpdate( { styles, image, setImage } : any) {

    const inputRef = useRef<any>(null);

    const handleImageClick = () => {
        if(inputRef.current !== null) {
            inputRef.current.click();
        }
    }

    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        setImage(file);
    }

    return (
        <div onClick={ handleImageClick}  style={{ width: styles.width, height: styles.height }} className="image-wrapper">
            <img src={ URL.createObjectURL(image) } style={{  width: styles.width, height: styles.height, borderRadius: "50%"}} ></img>
            <div className="uploader">
                <input type="file" ref={inputRef}  onChange={handleImageChange} className="edit-image" />
                
                <div style={{position:"absolute", bottom: "1vw", left: "4.5vw", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: styles.marginBottom, marginLeft: styles.marginLeft }}>
                    <CameraAltOutlinedIcon sx={{fontSize: "2vw", color: "#fff"}}></CameraAltOutlinedIcon>
                    <p>Update</p>
                </div>
                
            </div>
        </div>
    );
}