import React, { useEffect, useState } from "react";
import "./subscribe.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";

import { createTheme, ThemeProvider } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import { FaRegHandshake } from "react-icons/fa";
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { FiAlertTriangle } from "react-icons/fi";


export const Subscribe: React.FC = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        agreedInfoCampaign: false,
        agreedMarketingCampaign: false,
    });

    const [emailError, setEmailError] = useState(false);
    const [apiMessage, setApiMessage] = useState<{ message: string; success: boolean } | null>(null);

    // {message: "Вече сте регистрирани!", success: false}
    const apiKey = process.env.REACT_APP_USER_SUBSCRIBE_AZURE_FUNCTION_API_KEY || "";
    const subscribeURL = process.env.REACT_APP_USER_SUBSCRIBE_AZURE_FUNCTION_URL || "";

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: type === "checkbox" ? checked : value,
        }));
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setFormData((prev) => ({ ...prev, email: value }));
        setEmailError(!e.target.validity.valid);
    };

    const handleSubscribe = async () => {
        const { email, name, agreedInfoCampaign, agreedMarketingCampaign } = formData;
        const subscribeBody = { email, name, agreedInfoCampaign, agreedMarketingCampaign };
    
        try {
            const response = await fetch(subscribeURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-functions-key": apiKey,
                },
                body: JSON.stringify(subscribeBody),
            });
            const responseData = await response.json();
            if (response.ok) {
                setApiMessage({
                    message: responseData.message,
                    success: true,
                });
            } else {
                setApiMessage({
                    message: responseData.message,
                    success: false,
                });
            }
        } catch (error) {
            console.error("Subscription failed:", error);
            setApiMessage({
                message: "Infrastructure issue. Contact the support team.",
                success: false,
            });
        }
        document.getElementById('subscribed')?.classList.add('show-subscribe');
    };
    
    useEffect(() => {
        if (apiMessage) {
            const timer = setTimeout(() => {
                setApiMessage(null); // Reset message after 3 seconds
                setFormData({         // Reset form data
                    name: "",
                    email: "",
                    agreedInfoCampaign: false,
                    agreedMarketingCampaign: false,
                });
            }, 3000); // 3 seconds
    
            return () => clearTimeout(timer);
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                entry.isIntersecting
                    ? entry.target.classList.add("show")
                    : entry.target.classList.remove("show");
            });
        });

        const hiddenLeft = document.querySelectorAll(".hide-left");
        const hiddenRight = document.querySelectorAll(".hide-right");

        hiddenLeft.forEach((el) => observer.observe(el));
        hiddenRight.forEach((el) => observer.observe(el));

        return () => observer.disconnect();
    }, [apiMessage]);

    const customTheme = createTheme({
        components: {
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        display: "none",
                        fontSize: '1.2rem',
                        color: "red",
                        fontFamily: "Ubuntu",
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontSize: '1.3rem',
                        fontFamily: "Ubuntu",
                    },
                }
            },
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        fontSize: '1.8vw'
                    }
                }
            },
            MuiCheckbox: {
                styleOverrides: {
                    checked: {
                        color: "rgba(40, 180, 133, 0.7)"
                    }
                }
            }
        },
    });

    const { name, email, agreedInfoCampaign, agreedMarketingCampaign } = formData;
    let Logo = require('../../../assets/paw-glitch-bright.png');
    let dog_and_human = require('../../../assets/dog_and_human.png');

    return (
        <div className="section-subscribe">
            <div className="main-container">
                <div className="form-container hide-left">
                    <div className="text-container">
                        <h2 className="mobile_header_txt">Бъдете сред първите изпробвали нашата платформа</h2>
                        <p className="mobile-sub-txt">Просто попълнете нашата кратка форма</p>
    
                        <div className="form-header-text">
                            <MarkEmailReadRoundedIcon className="form-header-img"/>
                            <p>Ние ще ви известим с имейл, когато платформата е активна за ползване.</p>
                        </div>
                    </div>
    
                    {apiMessage ? (
                        <div className={`subscribed-message-cont show ${apiMessage.success ? 'success-message' : 'error-message'}`}>
                            <div id="subscribed" className="subscribed-cont">
                                {apiMessage.success ? (
                                    <CheckCircleIcon className="subscribed-icon"/>
                                ): (
                                    <FiAlertTriangle className="error-icon" />
                                )}

                                <p className={apiMessage.success ? "heading-sub-text success_txt" : "heading-sub-text error_txt"}>{apiMessage.message}</p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className="form-inputs-container">
                                <div className="form-inputs">
                                    <FormControl className="form-input-field">
                                        <ThemeProvider theme={customTheme}>
                                            <TextField
                                                required
                                                id="username"
                                                label="Име"
                                                name="name"
                                                value={name}
                                                variant="outlined"
                                                color="success"
                                                sx={{ width: "100%", marginBottom: "1.2rem" }}
                                                onChange={handleInputChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {name.length >= 3 && (
                                                                <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </ThemeProvider>
                                    </FormControl>
    
                                    <FormControl className="form-input-field">
                                        <ThemeProvider theme={customTheme}>
                                            <TextField
                                                required
                                                id="email"
                                                label="Имейл"
                                                name="email"
                                                value={email}
                                                variant="outlined"
                                                color="success"
                                                sx={{ width: "100%", marginBottom: "1.2rem" }}
                                                onChange={handleEmailChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {email.length > 0 && !emailError && (
                                                                <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                    type: "email",
                                                }}
                                            />
                                        </ThemeProvider>
                                    </FormControl>
                                </div>
    
                                <div className="form-checks">
                                <div className="checkbox-container">
                                    <ThemeProvider theme={customTheme}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    onChange={handleInputChange}
                                                    name="agreedInfoCampaign"
                                                    checked={agreedInfoCampaign}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                          color: "rgba(40, 180, 133, 1)",
                                                        },
                                                    }}
                                                />
                                            }
                                            label=""
                                        />
                                    </ThemeProvider>
                                        <p className="checkbox-label">
                                            Съгласен/а съм да получавам информационни съобщения относно развитието на платформата PetConnect и с <a className="gdpr-link" href="/gdpr">Уведомлението за поверителност</a> <span className="mandatory">*</span>
                                        </p>   
                                    </div>
                                    <div className="checkbox-container">
                                        <ThemeProvider theme={customTheme}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        onChange={handleInputChange}
                                                        name="agreedMarketingCampaign"
                                                        checked={agreedMarketingCampaign}
                                                        sx={{
                                                            '&.Mui-checked': {
                                                              color: "rgba(40, 180, 133, 1)",
                                                            }
                                                        }}  
                                                    />
                                                }
                                                label=""
                                            />
                                        </ThemeProvider>
                                        <p className="checkbox-label">
                                            Съгласен/а съм личните ми данни да бъдат обработвани за целите на последващ директен маркетинг, свързан с изпращането на търговски и маркетингови съобщения
                                        </p> 
                                    </div>
                                </div>
                            </div>
    
                            <Button
                                className="form-button"
                                variant="contained"
                                color="success"
                                type="submit"
                                onClick={handleSubscribe}
                                disabled={!agreedInfoCampaign || name.length < 3 || email === '' || emailError}
                            >
                                Искам да бъда информиран
                            </Button>
    
                            <div className="message-cont">
                                <p className="message-text">Ние няма да споделим вашата информация с никого!</p>
                                <FaRegHandshake className="message-icon"/>
                            </div>
                        </div>
                    )}
                </div>
                <div className="phone-container">
                    <div className="phone hide-right">
                        <div className="phone-nav">
                            <div className="glitch_mobile_wrap">
                                <div className="header_logo glitch_mobile"></div>
                            </div>

                            <MenuIcon sx={{ fontSize: "3vw", transform: "rotateZ(-9deg)", mt: "-1vw"}}></MenuIcon>
                        </div>
                        
                        {/* <p className="phone-txt">PawsConnect</p> */}

                        <div className="features">
                            <div className="feature" style={{ marginLeft: "1vw" }}>
                                <FavoriteBorderOutlinedIcon sx={{ fontSize: "1.3vw", color: "rgba(40, 180, 133, 0.7)" }}></FavoriteBorderOutlinedIcon>
                                <p className="feature-txt">Намери партньор</p>
                            </div>

                            <div className="feature" style={{ marginLeft: "1.5vw" }}>
                                <PermMediaOutlinedIcon sx={{ fontSize: "1.3vw", color: "rgba(40, 180, 133, 0.7)" }}></PermMediaOutlinedIcon>
                                <p className="feature-txt">Социална мрежа</p>
                            </div>

                            <div className="feature" style={{ marginLeft: "2vw" }}>
                                <MessageOutlinedIcon sx={{ fontSize: "1.3vw", color: "rgba(40, 180, 133, 0.7)" }}></MessageOutlinedIcon>
                                <p className="feature-txt">Форум</p>
                            </div>
                        </div>

                        <img src={dog_and_human} style={{ width: "13vw", height: "13vw", marginTop: "2vw", marginLeft: "4vw" }}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};