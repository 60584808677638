import { useEffect } from "react";

export const usePageRefresh = () => {
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("source");
      localStorage.removeItem("showSnackbar");
    };

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
};
