import { useState, useEffect, useRef } from "react";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Button from "@mui/material/Button";
import "./dogProfileCard.css";
import FileUpdate from './FileUpdate';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";


const DogProfileCard = ({ pet }: any) => {
    const inputRef = useRef<any>(null);
    const [image, setImage] = useState(pet.image || '');

    const dogImage = require('../../../../assets/Dog_pic_2.jpg');

    const handleImageClick = () => {
        if(inputRef.current !== null) {
            inputRef.current.click();
        }
    }

    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        console.log(file);

        setImage(file);
    }

    const image_update_styles = {
        width: "11vw",
        height: "10vw",
        marginBottom: "-1vw",
        marginLeft: "-0.4vw"
    }

    return (
        <div className="card_containter">
            <div className="card-content">
                {/* <img src="./tongue.png" alt="dog" className="dog_image" />  */}
            
            {dogImage != "" ? (

                 <div onClick={ handleImageClick} className="image-wrapper" style={{ opacity: "1", width: "16rem", height: "16rem"}}>
                        {/* <img src={ URL.createObjectURL(image) }  style={{ width: "11vw", height: "10vw", borderRadius: "50%"}} ></img> */}
                        <img src={dogImage} style={{width: "100%", height: "100%"}} /> 

                        <div className="uploader">
                            <input type="file" ref={inputRef}  onChange={handleImageChange} className="edit-image" />
                            
                            <div style={{position:"absolute", bottom: "1.6rem", left: "4.5vw", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "-1vw", marginLeft: "-0.4vw"}}>
                                <CameraAltOutlinedIcon sx={{fontSize: "2vw", color: "#fff"}}></CameraAltOutlinedIcon>
                                <p>Update</p>
                            </div>
                            
                        </div>
                    </div>
            ): (
                    <div className="image-wrapper" style={{ opacity: "1", width: "16rem", height: "16rem"}}>
                        {/* <AccountBoxOutlinedIcon sx={{fontSize: "8rem"}}></AccountBoxOutlinedIcon>  */}
                        <img src={dogImage} style={{width: "100%", height: "100%"}} /> 

                    </div>
            )}

                <div className="dog-details-container">
                    <div className="dog-details-header">
                        <p className="dog-name">{pet.name}</p>
                        <p className="dog-breed">{pet.breed}</p>
                    </div>

                    <div className="main-container">
                        <div className="left-container">
                            <p className="dog-address">{pet.city + ', ' + pet.region}</p>
                            <p className="dog-sex">{pet.sex}</p>
                            <p className="dog-color">{pet.color}</p>
                            
                            <div className="checbox-container">
                                {pet.vaccinated === true ? (
                                <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                                ): (
                                    <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                                )}
                                <p style={{width: "100%"}}>Напълно ваксиниран(а)</p>
                            </div>

                            <div className="checbox-container">
                                {pet.original_breed === true ? (
                                <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                                ): (
                                    <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                                )}
                                <p style={{width: "100%"}}>Потвърдено родословие</p>
                            </div>
                        </div>

                        <div className="right-container">
                            <div style={{ textAlign: "left"}}>
                                <p>{pet.weight + " кг"}</p>
                                <p>{pet.height + " см"}</p>
                                <p>6 м</p>     
                            </div>
                            

                            <div className="buttons">

                                <Tooltip 
                                    title="ГАЛЕРИЯ" 
                                    arrow
                                    slotProps={{
                                        popper: {
                                            sx: {
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                {
                                                marginTop: '0.5rem',
                                                fontSize: '1.4rem',
                                                fontFamily: 'Ubuntu',
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                maxWidth: 220,
                                                backgroundColor: '#f5f5f9',
                                                border: '1px solid #dadde9',
                                                },
                                            },
                                        },
                                    }}
                                    onClick={ () => {}}
                                >
                                    <Button
                                        sx={{ transition: "all 0.5s", width: "18%", padding: "0.5rem", fontFamily: "Ubuntu", fontSize: "1.3rem", color: "#fff", backgroundColor: "rgba(40, 180, 133, 0.7)", "&:hover": {backgroundColor: "rgba(40, 180, 133, 0.9)", transform: "scale(1.03)"} }}
                                        variant="contained"
                                    >
                                        Повече
                                    </Button>
                                </Tooltip>

                                <Tooltip 
                                    title="РЕДАКТИРАНЕ" 
                                    arrow
                                    slotProps={{
                                        popper: {
                                            sx: {
                                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                                {
                                                marginTop: '0.5rem',
                                                fontSize: '1.4rem',
                                                fontFamily: 'Ubuntu',
                                                color: 'rgba(0, 0, 0, 0.87)',
                                                maxWidth: 220,
                                                backgroundColor: '#f5f5f9',
                                                border: '1px solid #dadde9',
                                                },
                                            },
                                        },
                                    }}
                                    onClick={ () => {}}
                                >
                                    <div className="edit-btn">
                                        <ModeEditOutlinedIcon sx={{ fontSize: "1.8vw", color: "#fff"}}></ModeEditOutlinedIcon>
                                    </div>
                                </Tooltip>

                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    );
};

export default DogProfileCard;