//import authReducer from "../state";

import authReducer from "../features/authSlice";
import themeReducer from "../features/themeSlice";
import messagesReducer from "../features/messagesSlice";
import notificationsReducer from "../features/notificationsSlice";
import languageReducer from "../features/languageSlice";

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  auth: authReducer,
  theme: themeReducer,
  messages: messagesReducer,
  notifications: notificationsReducer,
  language: languageReducer
});

const persistConfig = { key: "root", storage, version: 1 };
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<typeof rootReducer>;
export default store;
