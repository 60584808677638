import { ThemeOptions } from "@mui/material/styles";

// color design tokens export

export const colorTokens = {
  grey: {
    0: "#FFFFFF",
    10: "#F6F6F6",
    50: "#F0F0F0",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0A0A0A",
    1000: "#000000",
  },
  primary: {
    50: "#55c57a",
    100: "#7ed56f",
    200: "#28b485",
    300: "#66E6FC",
    400: "#33DDFB",
    500: "#00D5FA",
    600: "#00A0BC",
    700: "#006B7D",
    800: "#00353F",
    900: "#E1FAE1",
  },
};

//mui theme settings

export const themeSettings = (mode: "light" | "dark") => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // pallete values for dark mode
            primary: {
              dark: colorTokens.primary[200],
              main: colorTokens.primary[100],
              light: colorTokens.primary[50],
            },
            neutral: {
              dark: colorTokens.grey[100],
              main: colorTokens.grey[200],
              mediumMain: colorTokens.grey[300],
              medium: colorTokens.grey[400],
              light: colorTokens.grey[700],
            },
            background: {
              default: colorTokens.grey[900],
              alt: colorTokens.grey[800],
              dedov: colorTokens.grey[800],
            },
            iconColor: "#FFFFFF4a",
          }
        : {
            // pallete values for light mode

            primary: {
              dark: colorTokens.primary[200],
              main: colorTokens.primary[100],
              light: colorTokens.primary[50],
            },
            neutral: {
              dark: colorTokens.grey[700],
              main: colorTokens.grey[500],
              mediumMain: colorTokens.grey[400],
              medium: colorTokens.grey[300],
              light: colorTokens.grey[50],
            },
            background: {
              default: colorTokens.grey[10],
              alt: colorTokens.grey[0],
            },
            iconColor: "#1e1e1e",
          }),
    },
    breakpoints: {
      values: {
        xs: 0, // Extra small devices (portrait phones)
        sm: 600, // Small devices (landscape phones)
        md: 960, // Medium devices (tablets)
        lg: 1280, // Large devices (desktops)
        xl: 1920, // Extra large devices (large desktops)
      },
    },
    typography: {
      h1: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Rubik", "sans-serif"].join(","),
        fontSize: 14,
      },
      body1: {
        fontSize: "1.1rem", // Default font size for body1
        fontWeight: "bold",
        "@media (min-width:600px)": {
          fontSize: "1.2rem", // Font size for screens 600px and above
        },
        "@media (min-width:960px)": {
          fontSize: "1.4rem", // Font size for screens 960px and above
        },
        "@media (min-width:1280px)": {
          fontSize: "1.6rem", // Font size for screens 1280px and above
        },
      },
    },
    components: {
      MuiMenuItem: {},
    },
  };
};
