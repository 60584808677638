import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material";

export const StyledMenuItem = styled(MenuItem)(
  ({ theme }) =>
    `
  &:hover {
    
  }
`
);
