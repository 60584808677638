import React from "react";
import "./home.css";
import { useAuth } from "react-oidc-context";
import { Navbar } from "../../navbar/Navbar";


export const Home: React.FC = () => {
  const shelterImage = require("../../../images/house.png");
  const dogPartnerImage = require("../../../images/dog.png");

  const username = "Илиян";

  return (
    <div className="home_container">
      <Navbar />

      <div className="welcome_message_container">
          <p className="welcome_message">Добре дошли,</p>
          <p className="welcome_message-name">{username}</p>
      </div>

      <div className="feature_cards_container">

        <div id="" className="feature_card" >
            <div className="feature_card_header border-gradient border-gradient-green only-bottom">
              <div className="image_container">
                <img src={dogPartnerImage} className="feature_card_img"/>
              </div>
              <p className="feature_card_txt">Намерете партньор</p>
            </div>
        </div> 

        <div id="" className="feature_card" >
          <div className="feature_card_header border-gradient border-gradient-green only-bottom">
            <div className="image_container">
              <img src={shelterImage} className="feature_card_img"/>
            </div>
            <p className="feature_card_txt">Осиновете животно</p>
          </div>
          
        </div> 

      </div>
    </div>
  );
};
