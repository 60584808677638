import "./profileSummary.css";
import React, { useState } from "react";

import { ThemeProvider, createTheme  } from '@mui/material/styles';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import FileUpload from "./FileUpload";
import FileUpdate from './FileUpdate';
import Modal from "../../modal/Modal";

export default function ProfileSummary () {
    const [profileImage, setProfileImage] = useState("");
    const [showModal, setShowModal] = useState(false);
    
    const profile_upload_styles = {
        fontSize: "4vw", 
        color: "#7a7a83", 
        cursor: "pointer",
        "&:hover": { color: "rgba(40, 180, 133, 0.7)" }
    }

    const profile_update_styles = {
        width: "8vw",
        height: "8vw",
        marginBottom: "-0.7vw",
        marginLeft: "-2.4rem"
    }

    const handleModal = function() {
        setShowModal(true);
    }

    return (
        <div className="profile-summary-cont">
            <Modal showModal={ showModal } setShowModal={ setShowModal } pets={ {} } setPets={ {} } content="editProfile"></Modal>

            {profileImage && typeof profileImage !== 'string' ? (
                <FileUpdate styles={ profile_update_styles } image={ profileImage } setImage={ setProfileImage }></FileUpdate>
                ): (
                <div className="upload-cont">
                    <FileUpload styles={ profile_upload_styles } image={ profileImage } setImage={ setProfileImage }></FileUpload>
                </div>
            )}

            <div className="user-details">
                <div className="top-details">
                    <p className="user-name">Илиян Неделчев</p>
                    <div className="actions">
                        <Tooltip 
                            title="ПРОФИЛ" 
                            arrow
                            slotProps={{
                            popper: {
                                sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                    marginTop: '0.5rem',
                                    fontSize: '1.4rem',
                                    fontFamily: 'Ubuntu',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    maxWidth: 220,
                                    backgroundColor: '#f5f5f9',
                                    border: '1px solid #dadde9',
                                    },
                                },
                            },
                            }}
                        >
                            <div className="action-cont">
                                <PersonAddAltOutlinedIcon sx={{ fontSize: "1.8vw", color: "#fff"}}></PersonAddAltOutlinedIcon>
                            </div>
                        </Tooltip>

                        <Tooltip 
                            title="СЪОБЩЕНИЯ" 
                            arrow
                            slotProps={{
                            popper: {
                                sx: {
                                [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                    {
                                    marginTop: '0.5rem',
                                    fontSize: '1.4rem',
                                    fontFamily: 'Ubuntu',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    maxWidth: 220,
                                    backgroundColor: '#f5f5f9',
                                    border: '1px solid #dadde9',
                                    },
                                },
                            },
                            }}
                        >
                            <div className="action-cont">
                                <SmsOutlinedIcon sx={{ fontSize: "1.8vw", color: "#fff"}}></SmsOutlinedIcon>
                            </div>
                        </Tooltip>
                            
                        <Tooltip 
                            title="РЕДАКТИРАНЕ" 
                            arrow
                            slotProps={{
                                popper: {
                                    sx: {
                                    [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                        {
                                        marginTop: '0.5rem',
                                        fontSize: '1.4rem',
                                        fontFamily: 'Ubuntu',
                                        color: 'rgba(0, 0, 0, 0.87)',
                                        maxWidth: 220,
                                        backgroundColor: '#f5f5f9',
                                        border: '1px solid #dadde9',
                                        },
                                    },
                                },
                            }}
                            onClick={handleModal}
                        >
                            <div className="action-cont">
                                <ModeEditOutlinedIcon sx={{ fontSize: "1.8vw", color: "#fff"}}></ModeEditOutlinedIcon>
                            </div>
                        </Tooltip>            
                    </div>
                </div>
                <p className="user-address">София, София-град</p>
            </div>

            {/* <ClearOutlinedIcon sx={{ fontSize: "2.3vw", ml: "5vw", mt: "1vw", cursor: "pointer"}}></ClearOutlinedIcon> */}
        </div>
    );
};
