import React from "react";
import Button from "@mui/material/Button";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

export const EmptyChatList = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ textAlign: "center" }}>
        Нямате нови съобщения. Добавете приятели и започнете да чатите
      </div>
      <Button variant="outlined" startIcon={<GroupAddIcon />} sx={{mt: 2.5}}>
        Добавете
      </Button>
    </div>
  );
};
