import React from "react";
import "./footer.css";
import ReactGA from 'react-ga4';

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { useTranslation } from "react-i18next";

import websiteLogo from "../../../assets/paw-white.png";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="primary-footer">
      <div className="footer-container">
        <div className="primary-footer-wrapper">

          <div className="primary-footer-logo-social">
            <div className="footer-logo-cont"> 
              <a href="#">
                <img
                  src={websiteLogo}
                  alt="Logo"
                  className="footer-logo"
                />
              </a>
              <div >
                <p style={{color: "#fff"}}>© 2024 PetConnect ltd.</p>
                <p style={{color: "#fff"}}>All Rights Reserved.</p>
              </div>
              
            </div>
             
            <div className="socials">
              <p>Последвайте ни</p>
              <ul className="social-list" role="list" aria-label="Social links">
                <li onClick={() => { window.open("https://www.facebook.com/profile.php?id=61561869945434", '_blank'); }}>
                  <span aria-label="facebook">
                    <FacebookIcon className="social-icon" onClick={() => {
                        ReactGA.event(
                          {
                              category: "Landing",
                              action: "btn_facebook"
                          }
                        )
                      }}
                    />
                  </span>
                </li>
    
                <li onClick={() => { window.open("https://www.instagram.com/petconnect.global/", '_blank'); }}>
                  <span aria-label="instragram">
                    <InstagramIcon className="social-icon" onClick={() => {
                        ReactGA.event(
                          {
                              category: "Landing",
                              action: "btn_instagram"
                          }
                        )
                      }}
                    />
                  </span>
                </li>

                {/* <li>
                  <span aria-label="linkedin">
                    <LinkedInIcon className="social-icon" onClick={() => {
                        ReactGA.event(
                          {
                              category: "Landing",
                              action: "btn_linkedin"
                          }
                        )
                      }}
                    />
                  </span>
                </li> */}
              </ul>  
            </div>
          </div>
      
            <nav className="footer-nav">
              <ul className=""  aria-label="Footer" role="list">
                <li>
                  <a href="/team" onClick={() => {
                    ReactGA.event(
                      {
                          category: "Landing",
                          action: "btn_team"
                      }
                    )
                  }}>Нашият екип</a>
                </li>
                <li>
                  <a href="/gdpr" onClick={() => {
                    ReactGA.event(
                      {
                          category: "Landing",
                          action: "btn_gpdr"
                      }
                    )
                  }}>Уведомление за поверителност</a>
                </li>
                <li>
                  <a href="#" onClick={() => {
                    ReactGA.event(
                      {
                          category: "Landing",
                          action: "btn_contacts"
                      }
                    )
                  }}>Свържете се с нас</a>
                </li>
              </ul>
            </nav>
          
          
        </div>
      </div>
    </footer>
  );
};
