import React from "react";
import { Badge } from "./CustomBadge";
import { SvgIconProps } from "@mui/material/SvgIcon";

interface IconWithBadgeProps {
  icon: React.ReactElement<SvgIconProps>;
  badgeCount: number;
  iconStyles?: React.CSSProperties;
}

export const IconWithBadge: React.FC<IconWithBadgeProps> = ({
  icon,
  badgeCount,
  iconStyles,
}) => {
  return (
    <>
      {React.cloneElement(icon, { style: iconStyles })}
      <Badge count={badgeCount} />
    </>
  );
};
