import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './locales/en.json';
import bgTranslation from './locales/bg.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // Use language detector plugin
  .init({
    resources: {
      en: {
        translation: {
          ns1: enTranslation
        }
      },
      bg: {
        translation: {
          ns1: bgTranslation
        }
      },
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'], // Specify detection order
      caches: ['cookie'], // Optional: Cache detected language in cookie
    },
  });

export default i18n;