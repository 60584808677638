import React from 'react';
import "./navbar.css";
import {
  IconButton,
  InputBase,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

import SearchIcon from '@mui/icons-material/Search';
import { useEffect } from "react";

import AccountMenu from "./AccountMenu";
import { NotificationsMenu } from "./notificationsMenu/NotificationsMenu";
import { ChatMenu } from "./chatMenu/ChatMenu";

import { Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import FlexBetween from "../FlexBetween";

import { setMessages } from "../../features/messagesSlice";
import { setNotifications } from "../../features/notificationsSlice";
import { listData,notifications } from "./TestData";

export const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;

  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;


  const testKey = process.env.REACT_APP_TEST_KEY;

/*   useEffect(() => {
    dispatch(setMessages(listData));
    dispatch(setNotifications(notifications));
    console.log(testKey);
  }, []); */

  return (
    <>
      <FlexBetween
        padding="1rem 6%"
        sx={{
          backgroundColor: alt,
        }}
      >
        {/* DESKTOP NAV */}

        {/* LOGO + NAME */}
        <div className="home_glitch_wrap">
            <div className="home_header_logo home_glitch"></div>
        </div>
        <div className="home_glitch-text_wrap">
            <p data-text="PetConnect" className="home_glitch-text fast-anim">PetConnect</p>
        </div>

        <div className="nav-search-container">
         
        </div>

        <FlexBetween gap="4rem">
          <span className="home_nav-text">За нас</span>
          <span className="home_nav-text">Контакти</span>
        </FlexBetween>

        <FlexBetween gap="2rem">
          <NotificationsMenu />
          <ChatMenu />
          <AccountMenu />
        </FlexBetween>
      </FlexBetween>
    </>
  );
};
