import React from "react";
import styles from "./badgeContent.module.css";

interface BadgeProps {
  count: number;
}

export const Badge: React.FC<BadgeProps> = ({ count }) => {
  return count > 0 ? <div className={styles["badge"]}>{count}</div> : null;
};
