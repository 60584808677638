import ReactDOM from "react-dom/client";
import App from "./App";
import "./app.css";

import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

import store from "./app/store";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import { createRoot } from "react-dom/client";
import { StrictMode } from "react";

// import { KcPage, type KcContext } from './keycloak-theme';
import { KcPage, type KcContext } from "./keycloak-theme/kc.gen";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistStore(store)}>
      <StrictMode>
        {!window.kcContext ? (
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>) : (
            <KcPage kcContext={window.kcContext} />
        )}
      </StrictMode>
    </PersistGate>
  </Provider>
);
/* 
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistStore(store)}>
      <StrictMode>
          <I18nextProvider i18n={i18n}>
            <App />
          </I18nextProvider>
      </StrictMode>
    </PersistGate>
  </Provider>
); */
