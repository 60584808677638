import React, { useEffect } from "react";
import { FaDog, FaPhotoVideo, FaSearchLocation  } from "react-icons/fa"
import { BiCommentDetail } from "react-icons/bi";
import "./landingSeparatorHorizontal.css";

export const LandingSeparatorHorizontal: React.FC = () => {

  return (
    <section className="section-separator horizontal">
      
      <div className="separator-text-cont">
        <span className="">Всичко, от което има нужда вашия любимец</span>
      </div>
    
    </section>
  );
};
