import React from "react";
import { useState, useEffect } from "react";
import ReactGA from 'react-ga4';
import "./header.css";
import { useTranslation } from "react-i18next";

import { NavLink as Link } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

const scrollToSection = (sectionId: string): void => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop,
        behavior: "smooth",
      });
    }
};



export const Header: React.FC = () => {

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    let video = require("../../../assets/DogVideo.webm");

    return (
        <header className="header cont-row">
             <div className="bg-video">
                <video className="bg-video__content" autoPlay muted loop>
                <source src={video} type="video/webm"></source>
                Your Browser is not supported!
                </video>
            </div>
            <div  className="header-nav">

                <div className="text-logo-cont">
                    <div className="glitch_wrap">
                        <div className="header__logo glitch"></div>
                    </div>
                    <div className="glitch-text_wrap" style={{ marginLeft: "-2vw"}}>
                        <p data-text="PetConnect" className="glitch-text fast-anim">PetConnect</p>
                    </div>
                </div>
                

                {!isMobile && (
                    <div className="header-nav-links">
                        <a href="/team">Нашият екип</a>
                        <a href="/team">Контакти</a>
                    </div>
                )}

                {isMobile && (
                    <div className="mobile-menu-cont">
                        <Tooltip title="Account settings">
                            <IconButton
                            onClick={handleClick}
                            size="small"
                            aria-controls={open ? "account-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            >
                                <MenuOutlinedIcon sx={{ color: "#fff", fontSize: "3rem" }} />
                            </IconButton>
                        </Tooltip>

                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: "auto",
                                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                    mt: 0,
                                    ml: -2,
                                    "& .MuiAvatar-root": {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    }
                                },
                            }}

                        >
                            <MenuItem>
                                <Typography>Контакти</Typography>
                            </MenuItem>
                            <MenuItem>
                                <Typography>Нашият екип</Typography>
                            </MenuItem>
        
                        </Menu>
                        
                    </div>
                )}


                

            </div>

            <div className="header__text-box">
                <h1 className="heading-primary">

                    <div className="header-main-text-cont">
                        <p className="header_main_text">Една платформа за всичко</p>
                    </div>
        
                    <p className="header_sub_text">от което се нуждае Вашия любимец</p>
                </h1>

                <div className="arrow-indicators-container">
                    <ExpandMoreIcon className="arrow-icon moveDown" sx={{ fontSize: "7vw", color: "#fff", marginTop: "-5vw" }}/>
                </div>
            </div>
        </header>
    )
}