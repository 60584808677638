import "./registerForm.css";

import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import { Button } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import { SimpleStepper } from "./SimpleStepper";
import InputAdornment from "@mui/material/InputAdornment";
import Autocomplete from '@mui/material/Autocomplete';


import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import PersonIcon from '@mui/icons-material/Person';
import PetsIcon from '@mui/icons-material/Pets';
import StoreIcon from '@mui/icons-material/Store';
import RoofingIcon from '@mui/icons-material/Roofing';
import IconButton from '@mui/material/IconButton';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import SmsFailedOutlinedIcon from '@mui/icons-material/SmsFailedOutlined';
import DoDisturbAltOutlinedIcon from '@mui/icons-material/DoDisturbAltOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const RegisterForm = (): JSX.Element => {
    //DUMMY DATA
    const cities = [
        {city: 'Айтос',district: 'Бургас'},
        {city: 'Аксаково',district: 'Варна'},
        {city: 'Алфатар',district: 'Силистра'},
        {city: 'Антоново',district: 'Търговище'},
        {city: 'Априлци',district: 'Ловеч'},
        {city: 'Ардино',district: 'Кърджали'},
        {city: 'Асеновград',district: 'Пловдив'},
        {city: 'Ахелой',district: 'Бургас'},
        {city: 'Ахтопол',district: 'Бургас'},
        {city: 'Балчик',district: 'Добрич'},
        {city: 'Банкя',district: 'София град'},
        {city: 'Банско',district: 'Благоевград'},
        {city: 'Баня',district: 'Пловдив'},
        {city: 'Батак',district: 'Пазарджик'},
        {city: 'Батановци',district: 'Перник'},
        {city: 'Белене',district: 'Плевен'},
        {city: 'Белица',district: 'Благоевград'},
        {city: 'Белово',district: 'Пазарджик'},
        {city: 'Белоградчик',district: 'Видин'},
        {city: 'Белослав',district: 'Варна'},
        {city: 'Берковица',district: 'Монтана'},
        {city: 'Благоевград',district: 'Благоевград'},
        {city: 'Бобовдол',district: 'Кюстендил'},
        {city: 'Бобошево',district: 'Кюстендил'},
        {city: 'Божурище',district: 'Софийска'},
        {city: 'Бойчиновци',district: 'Монтана'},
        {city: 'Болярово',district: 'Ямбол'},
        {city: 'Борово',district: 'Русе'},
        {city: 'Ботевград',district: 'Софийска'},
        {city: 'Брацигово',district: 'Пазарджик'},
        {city: 'Брегово',district: 'Видин'},
        {city: 'Брезник',district: 'Перник'},
        {city: 'Брезово',district: 'Пловдив'},
        {city: 'Варна',district: 'Варна'},
        {city: 'Велико Търново',district: 'Велико Търново'},
        {city: 'Велинград',district: 'Пазарджик'},
        {city: 'Видин',district: 'Видин'},
        {city: 'Враца',district: 'Враца'},
        {city: 'Габрово',district: 'Габрово'},
        {city: 'Годеч',district: 'Софийска'},
        {city: 'Гоце Делчев',district: 'Благоевград'},
        {city: 'Гълъбово',district: 'Стара Загора'},
        {city: 'Девин',district: 'Смолян'},
        {city: 'Добрич',district: 'Добрич'},
        {city: 'Доспат',district: 'Смолян'},
        {city: 'Елин Пелин',district: 'Софийска'},
        {city: 'Елена',district: 'Велико Търново'},
        {city: 'Етрополе',district: 'Софийска'},
        {city: 'Златоград',district: 'Смолян'},
        {city: 'Златица',district: 'Софийска'},
        {city: 'Игнатиево',district: 'Варна'},
        {city: 'София',district: 'София град'},
    ];

    const options = cities.map((option) => {
        const firstLetter = option.city[0].toUpperCase();
            return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
            };
    });

    //-------------------------------------------------------------------------------------

    // PHONE CODES
    interface CountryType {
        code: string;
        label: string;
        phone: string;
        suggested?: boolean;
    }

    // From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
    const countries: readonly CountryType[] = [     
    { code: 'AL', label: 'Албания', phone: '+355' },
    { code: 'AT', label: 'Австрия', phone: '+43' },
    { code: 'BA', label: 'Босна и Херцеговина', phone: '+387'},
    { code: 'BE', label: 'Белгия', phone: '+32' },
    { code: 'BG', label: 'България', phone: '+359' },
    { code: 'BY', label: 'Беларус', phone: '+375' },
    { code: 'CA', label: 'Канада',  phone: '+1', suggested: true,},
    { code: 'CH', label: 'Швейцария', phone: '+41' },
    { code: 'CY', label: 'Кипър', phone: '+357' },
    { code: 'CZ', label: 'Чехия', phone: '+420' },
    { code: 'DE', label: 'Германия', phone: '+49', suggested: true},
    { code: 'DK', label: 'Дания', phone: '+45' },
    { code: 'ES', label: 'Испания', phone: '+34' },
    { code: 'FI', label: 'Финландия', phone: '+358' },
    { code: 'FR', label: 'Франция', phone: '+33', suggested: true},
    { code: 'GB', label: 'Англия', phone: '+44' },
    { code: 'GR', label: 'Гърция', phone: '+30' },
    { code: 'HR', label: 'Хърватия', phone: '+385' },
    { code: 'HU', label: 'Унгария', phone: '+36' },
    { code: 'IE', label: 'Ирландия', phone: '+353' },
    { code: 'IT', label: 'Италия', phone: '+39' },
    { code: 'MK', label: 'Македония', phone: '+389',},
    { code: 'NL', label: 'Холандия', phone: '+31' },
    { code: 'NO', label: 'Норвегия', phone: '+47' },
    { code: 'PL', label: 'Полша', phone: '+48' },
    { code: 'PT', label: 'Португалия', phone: '+351' },
    { code: 'RO', label: 'Румъния', phone: '+40' },
    { code: 'RS', label: 'Сърбия', phone: '+381' },
    { code: 'RU', label: 'Русия', phone: '+7' },
    { code: 'SE', label: 'Швеция', phone: '+46' },
    { code: 'SI', label: 'Словения', phone: '+386' },
    { code: 'SK', label: 'Словакия', phone: '+421' },  
    { code: 'TR', label: 'Турция', phone: '+90' },
    { code: 'UA', label: 'Украйна', phone: '+380' },
    { code: 'US', label: 'Америка', phone: '+1', suggested: true },
    ];

    //--------------------------------------------------------------------------------------

    const [profileType, setProfileType] = useState("");
    const [activeStep, setActiveStep] = useState(0);
    const [accessToken, setAccessToken] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [showPasswordValidators, setShowPasswordValidators] = useState(false);


    // ERRORS
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [repeatPasswordError, setRepeatPasswordError] = useState(false);

    // VALIDATION STATES
    const [lowerValidated, setLowerValidated] = useState(false);
    const [upperValidated, setUpperValidated] = useState(false);
    const [numberValidated, setNumberValidated] = useState(false);
    const [specialCharValidated, setSpecialCharValidated] = useState(false);
    const [lengthValidated, setLengthValidated] = useState(false);


    const [profileInfo, setProfileInfo] = useState({
        email: "",
        password: "",
        repeat_password: ""
    });

    const [personalInfo, setPersonalInfo] = useState({
        firstName: "",
        lastName: "",
        city: "",
        code: "",
        phone: "",
        vetDocumentNumber: ""
    });

    const [companyInfo, setCompanyInfo] = useState({
        companyName: "",
        EIK: "",
        address: "",
    });

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onChangeHandler = (e: SelectChangeEvent | React.ChangeEvent<HTMLInputElement>) => {
        setProfileInfo((state) => ({
          ...state,
          [e.target.name]: e.target.value,
        }));
    };

    const steps = [
    'Профил',
    'Лични данни',
    'Лични данни 2'
    ];

    const containsOnlyNumbers = (str: string) => {
        return /^\d+$/.test(str);
        // return /[0-9]+/.test(str);
    };
    
    const containsOnlyLetters = (str: string) => {
        return /^[a-zA-Z-]+$/.test(str);
    };

    function validateEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function validatePassword(password: string) {
        // const passwordRegex = /^[A-Za-z]\w{7,14}$/
        // return passwordRegex.test(password);

        //REGEX
        const lower = new RegExp('(?=.[a-z])');
        const upper = new RegExp('(?=.[A-Z])');
        const number = new RegExp('(?=.[0-9])');
        const special = new RegExp('(?=.[!@#\$%\^&\*])');
        const length = new RegExp('(?=.{8,})');

        // lowercase VALIDATION
        if(lower.test(password)) {
            setLowerValidated(true);
        }else {
            setLowerValidated(false);
        }

        // UPPERCASE VALIDATION
        if(upper.test(password)) {
            setUpperValidated(true);
        }else {
            setUpperValidated(false);
        }

        // number  VALIDATION
        if(number.test(password)) {
            setNumberValidated(true);
        }else {
            setNumberValidated(false);
        }

        // special character VALIDATION
        if(special.test(password)) {
            setSpecialCharValidated(true);
        }else {
            setSpecialCharValidated(false);
        }

        // length VALIDATION
        if(length.test(password)) {
            setLengthValidated(true);
        }else {
            setLengthValidated(false);
        }
    }

    const checkFormFields = () => {
        if(activeStep === 0) {
            return profileType === ""
        }
        else if (activeStep === 1) {
            return profileInfo.email === "" || profileInfo.password === "" || profileInfo.repeat_password === "" || profileInfo.password !== profileInfo.repeat_password || emailError;
        }
        else {
            return personalInfo.firstName === "" || personalInfo.lastName === "" || personalInfo.city === "" || personalInfo.phone === "";
        }
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfileInfo((state) => ({
            ...state,
            [e.target.name]: e.target.value,
        }));

        /* if (e.target.validity.valid) {
          setEmailError(false);
        } else {
          setEmailError(true);
        } */

        if(validateEmail(e.target.value)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("INPUT: " + e.target.value);
        console.log("PASSWORD: " + profileInfo.password);

        validatePassword(e.target.value);

        if(lowerValidated && upperValidated && specialCharValidated && numberValidated && lengthValidated) {
            setPasswordError(false);
        } else {
            setPasswordError(true);
        }

        setProfileInfo((state) => ({
            ...state,
            [e.target.name]: e.target.value,
        }));

        console.log("PASSWORD AGAIN: " + profileInfo.password);

    };

    const handleRepeatPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProfileInfo((state) => ({
            ...state,
            [e.target.name]: e.target.value,
        }));

        /* if(validatePassword(e.target.value)) {
            setRepeatPasswordError(false);
        } else {
            setRepeatPasswordError(true);
        } */
    };

    const handlePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPersonalInfo((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));

        if(containsOnlyNumbers(e.target.value)) {
            setPhoneError(false);
        }else {
            setPhoneError(true);
        }
    };

    const handleDocumentNumber= (e: React.ChangeEvent<HTMLInputElement>) => {

        if(containsOnlyNumbers(e.target.value) || e.target.value === ''){
            if(personalInfo.vetDocumentNumber.length < 8 || (personalInfo.vetDocumentNumber.length === 8 && e.target.value.length < personalInfo.vetDocumentNumber.length)) {    
                setPersonalInfo((data) => ({
                    ...data,
                    [e.target.name]: e.target.value,
                }));    
            } 
        }        
    };

    const handleActiveStep = (direction: string) => {
        if(direction == "forward") {
            if(activeStep == 2) return;

            const newStep = activeStep + 1;
            setActiveStep(newStep);
        }
        else if (direction == "back") {
            if(activeStep == 0) return;

            const newStep = activeStep - 1;
            setActiveStep(newStep);
        }
    };

    const handleProfileType = (type: string) => {
        setProfileType(type);

        const cardElements = document.querySelectorAll('.profile-type-card');

        cardElements.forEach((card) => {
            if(card.classList.contains('card-selected')) 
            {
                card.classList.remove('card-selected');
            }
        });

        const selectedElement = document.getElementById(type);
        selectedElement?.classList.add('card-selected');
    }

    // GET ACCESS TOKEN
    useEffect(() => {
        const getTokenURL = "http://localhost:8080/realms/myrealm/protocol/openid-connect/token";

        fetch(getTokenURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: new URLSearchParams({
                'grant_type': 'client_credentials',
                'client_id': 'admin-rest-client',
                'client_secret': 'Z53UJOek6cOe0HszTsckrgHucdJQplX1'
            })
            }).then((response) => {
                response.json().then((data) => {
                    setAccessToken(data.access_token);
                    console.log("Got the Token!!");
                })
        });

    },[]);

    // ADD BACK THE SELECTED CLASS TO THE PROFILE TYPE CARD
    useEffect(() => {
        if(activeStep === 0 && profileType != "") {
            const selectedElement = document.getElementById(profileType);
            selectedElement?.classList.add('card-selected');
        }
    },[activeStep]);

    const handleSubmit = async () => {
        const registerUserURL = "http://localhost:8080/admin/realms/myrealm/users";

        const registerBody= {
            "attributes": {
                "attribute_key": "test_value"
             },
            "credentials": [
                {
                    "temporary": false,
                    "type": "password",
                    "value": profileInfo.password
                }
            ],
            "username": profileInfo.email,
            "firstName": personalInfo.firstName,
            "lastName": personalInfo.lastName,
            "email": profileInfo.email,
            "emailVerified": false,
            "enabled": true
        }

        console.log(accessToken);

        fetch(registerUserURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + accessToken,
            },
            body: JSON.stringify(registerBody)
            }).then((response) => {
                response.json().then((data) => {
                    console.log(data);
                })
        });
    };

    const textFieldTheme = createTheme({
        components: {
            MuiFormHelperText: {
             styleOverrides: {
              root: {
                color: 'red',
                margin: '5px auto'
              }
            }
            },
            MuiInputLabel: {
            styleOverrides: {
                shrink: {
                  fontSize: "1.1rem"
                }
          }
            }
        }
    })
 
    return (
        <div className="register-form-container">

            {/* SELECTED PROFILE TYPE INDICATOR */}
            {(profileType !== "" && activeStep > 0) && (
                <div className="selected-profile-type-indicator">
                    {profileType === "personal" && (
                        <div className="selected-profile-type-indicator-cont">
                            <PersonIcon sx={{ color: "#65C466", fontSize: "1.7rem"}}/>
                            <p className="profile-type-title">Персонален профил</p>
                        </div>
                    )}

                    {profileType === "vet" && (
                        <div className="selected-profile-type-indicator-cont">
                            <PetsIcon sx={{ color: "#65C466", fontSize: "1.7rem"}}/>
                            <p className="profile-type-title">Ветеринарен профил</p>
                        </div>
                    )}

                    {profileType === "company" && (
                        <div className="selected-profile-type-indicator-cont">
                            <StoreIcon sx={{ color: "#65C466", fontSize: "1.7rem"}}/>
                            <p className="profile-type-title">Фирмен профил</p>
                        </div>
                    )}

                    {profileType === "shelter" && (
                        <div className="selected-profile-type-indicator-cont">
                            <RoofingIcon sx={{ color: "#65C466", fontSize: "1.7rem"}}/>
                            <p className="profile-type-title">Профил за Приют</p>
                        </div>
                    )}
                    
                </div>
            )}

            {/* STEPPER */}
            <div style={{ marginTop: "1vw", marginBottom: "-3vw" }}>
                <SimpleStepper steps={steps} activeStep={activeStep}></SimpleStepper>
            </div>

            {/* PROFILE TYPE SECTION */}
            {activeStep === 0 && (
                <section> 
                    <p className="register-form-info-message">Моля, изберете вида на Вашия профил</p>

                    {/* Тип профил */}

                    {/* <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <TextField
                            name="profile_type"
                            value={formData.profile_type}
                            select
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setFormData((data) => ({
                                ...data,
                                [event.target.name]: event.target.value,
                                }));
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {formData.profile_type.length != 0 && (
                                            <AccountCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                        )}
                                    </InputAdornment>)
                            }}
                            label="Тип профил"
                            color="success"
                        >
                            <MenuItem value={"Потребителски"}>Потребителски</MenuItem>
                            <MenuItem value={"Ветеринарен"}>Ветеринарен</MenuItem>
                            <MenuItem value={"Фирмен"}>Фирмен</MenuItem>
                            <MenuItem value={"Приют"}>Приют</MenuItem>
                        </TextField>
                    </FormControl> */}

                    <div className="profile-types-container">

                        <div id="personal" className="profile-type-card" onClick={ () => {
                                handleProfileType("personal")
                            }}>
                            <PersonIcon sx={{ color: "#65C466", fontSize: "2rem"}}/>
                            <p className="profile-type-title">Персонален</p>
                        </div>  

                        <div id="vet" className="profile-type-card" onClick={ () => {
                                handleProfileType("vet")
                            }}>
                            <PetsIcon sx={{ color: "#65C466", fontSize: "2rem"}}/>
                            <p className="profile-type-title">Ветеринарен</p>
                        </div>

                        <div id="company" className="profile-type-card" onClick={ () => {
                                handleProfileType("company")
                            }}>
                            <StoreIcon sx={{ color: "#65C466", fontSize: "2rem"}}/>
                            <p className="profile-type-title">Фирмен</p>
                        </div>  

                        <div id="shelter" className="profile-type-card" onClick={ () => {
                                handleProfileType("shelter")
                            }}>
                            <RoofingIcon sx={{ color: "#65C466", fontSize: "2rem"}}/>
                            <p className="profile-type-title">Приют</p>
                        </div>
                    </div>
                </section> 
            )}

            {/* PROFILE INFO SECTION */}
            {activeStep === 1 && (
                <section>
                    <p className="register-form-info-message">Моля, попълнете Вашите потребителски данни</p>

                    {/* Имейл */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <ThemeProvider theme={textFieldTheme}>
                            <TextField
                                id="username"
                                label="Имейл"
                                name="email"
                                autoComplete='off'
                                value={profileInfo.email}
                                variant="outlined"
                                color="success"
                                sx={{ width: "100%", fontSize: "1.4rem"}}
                                onChange={ handleEmailChange }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {(profileInfo.email.length < 3 && !emailError) && (
                                                <MailOutlineIcon color="success" sx={{ color: "grey", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                            {(emailError) && (
                                                <MailOutlineIcon color="success" sx={{ color: "#b20000", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                            {(profileInfo.email.length > 3 && !emailError) && (
                                                <MailOutlineIcon color="success" sx={{ color: "#65C466", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                        </InputAdornment>),
                                    type: "email"

                                    /* endAdornment: (
                                        <InputAdornment position="end">
                                            {formData.email.length >= 3 && (
                                                    <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1rem" }}/>
                                            )}
                                        </InputAdornment>), */
                                }}
                            />
                        </ ThemeProvider>

                        { emailError && (
                            <div className="errorContainer">
                                <SmsFailedOutlinedIcon sx={{ color: "red", fontSize: "1.1rem" }}/>
                                <p className="errorMessage">Моля въведете валиден имейл адрес</p>
                            </div>
                            
                        )}
                    </FormControl>

                    {/* Парола */}
                    <FormControl sx={{ width: "100%", marginTop: "1.1vw"}}>
                        <ThemeProvider theme={textFieldTheme}>
                            <TextField
                                id="password"
                                label="Парола"
                                name="password"
                                autoComplete="new-password"
                                type={showPassword ? 'text' : 'password'}
                                value={profileInfo.password}
                                variant="outlined"
                                color="success"
                                sx={{ width: "100%", fontSize: "1.4rem", marginBottom: "1rem" }}
                                onChange={ handlePasswordChange }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {/* NEUTRAL - GREY */}
                                            {profileInfo.password.length < 7 && (
                                                <VpnKeyIcon color="success" sx={{ color: "grey", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                            {/* SUCCESS - GREEN */}
                                            {(profileInfo.password.length >= 7 && !passwordError) && (
                                                <VpnKeyIcon color="success" sx={{ color: "#65C466", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                            {/* ERROR - RED */}
                                            {(profileInfo.password.length >= 7 && passwordError) && (
                                                <VpnKeyIcon color="success" sx={{ color: "#b20000", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                        </InputAdornment>),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            onMouseUp={handleMouseUpPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                    ),
                                }}
                            />
                        </ThemeProvider>


                        <div onClick={ () => {setShowPasswordValidators(!showPasswordValidators)}}>
                            <InfoOutlinedIcon className={ showPasswordValidators ? 'opened' : 'closed'} sx={{ zIndex: "20", fontSize: "1.1rem", position: "absolute", top: "0.2rem", right: "-2.2rem", cursor: "pointer"}}/>
                        </div>
                        
                        {/* VALIDATION TRACKER */}
                        {showPasswordValidators && (
                            <div className="tracker-box">
                                <div className={lowerValidated ? 'validated': 'not-validated'}>
                                    <p>Поне една малка буква</p>
                                    {lowerValidated ? (
                                        <CheckCircleOutlinedIcon className="list-icon valid"/>
                                    ) : (
                                        <CircleOutlinedIcon className="list-icon"/>
                                    )}
                                    
                                </div>
                                <div className={upperValidated ? 'validated': 'not-validated'}>
                                    <p>Поне една голяма буква</p>
                                    {upperValidated ? (
                                            <CheckCircleOutlinedIcon className="list-icon valid"/>
                                        ) : (
                                            <CircleOutlinedIcon className="list-icon"/>
                                        )
                                    }
                                </div>
                                <div className={numberValidated ? 'validated': 'not-validated'}>
                                    <p>Поне една цифра</p>
                                    {numberValidated ? (
                                            <CheckCircleOutlinedIcon className="list-icon valid"/>
                                        ) : (
                                            <CircleOutlinedIcon className="list-icon"/>
                                        )
                                    }
                                </div>
                                <div className={specialCharValidated ? 'validated': 'not-validated'}>
                                    <p>Поне един специален символ</p>
                                    {specialCharValidated ? (
                                            <CheckCircleOutlinedIcon className="list-icon valid"/>
                                        ) : (
                                            <CircleOutlinedIcon className="list-icon"/>
                                        )
                                    }
                                </div>
                                <div className={lengthValidated ? 'validated': 'not-validated'}>
                                    <p>Поне 8 символа</p>
                                    {lengthValidated ? (
                                            <CheckCircleOutlinedIcon className="list-icon valid"/>
                                        ) : (
                                            <CircleOutlinedIcon className="list-icon"/>
                                        )
                                    } 
                                </div>
                            </div> 
                        )}
                               
                    
                    </FormControl>

                    {/* Повтори Парола */}
                    <FormControl sx={{ width: "100%", marginTop: "1.1vw"}}>
                        <ThemeProvider theme={textFieldTheme}>
                            <TextField
                                id="repeat"
                                label="Повтори парола"
                                name="repeat_password"
                                type={showRepeatPassword ? 'text' : 'password'}
                                value={profileInfo.repeat_password}
                                variant="outlined"
                                color="success"
                                sx={{ width: "100%", fontSize: "1.4rem", marginBottom: "0.6rem" }}
                                onChange={ handleRepeatPasswordChange }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {/* NEUTRAL - GREY */}
                                            {(profileInfo.repeat_password.length === 0 || profileInfo.repeat_password.length < profileInfo.password.length) && (
                                                <VpnKeyIcon color="success" sx={{ color: "grey", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                            {/* SUCCESS - GREEN */}
                                            {(profileInfo.repeat_password.length >= 7 && profileInfo.repeat_password.length === profileInfo.password.length  && profileInfo.repeat_password === profileInfo.password && !repeatPasswordError) && (
                                                <VpnKeyIcon color="success" sx={{ color: "#65C466", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                            {/* ERROR - RED */}
                                            {(profileInfo.repeat_password.length > profileInfo.password.length || (profileInfo.repeat_password.length !== 0 && profileInfo.repeat_password.length === profileInfo.password.length && profileInfo.repeat_password !== profileInfo.password)) && (
                                                <VpnKeyIcon color="success" sx={{ color: "#b20000", fontSize: "1.3rem", mr: "0.6rem" }}/>
                                            )}
                                        </InputAdornment>),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowRepeatPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                onMouseUp={handleMouseUpPassword}
                                                edge="end"
                                            >
                                                {showRepeatPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ThemeProvider>
                    </FormControl>
                </section> 
            )}

            {/* PERSONAL INFO SECTION */}
            {(activeStep === 2 && (profileType === "personal" || profileType === "vet") ) && (
                <section>
                    <p className="register-form-info-message">Моля, попълнете Вашите лични данни</p>

                    {/* Име */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <TextField
                            id="firstName"
                            label="Име"
                            name="firstName"
                            value={personalInfo.firstName}
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", fontSize: "1.4rem"}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPersonalInfo((data) => ({
                                  ...data,
                                  [event.target.name]: event.target.value,
                                }));
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {personalInfo.firstName.length >= 3 && (
                                            <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1rem" }}/>
                                        )}
                                    </InputAdornment>),
                            }}
                        />
                    </FormControl>

                    {/* Фамилия */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <TextField
                            id="lastName"
                            label="Фамилия"
                            name="lastName"
                            value={personalInfo.lastName}
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", fontSize: "1.4rem"}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setPersonalInfo((data) => ({
                                  ...data,
                                  [event.target.name]: event.target.value,
                                }));
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {personalInfo.lastName.length >= 3 && (
                                            <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1rem" }}/>
                                        )}
                                    </InputAdornment>),
                            }}
                        />
                    </FormControl>

                    {/* Населено място */}
                    <Autocomplete
                        options={options.sort((a, b) => -b.district.localeCompare(a.district))}
                        groupBy={(option) => option.district}
                        getOptionLabel={(option) => option.city}
                        inputValue={personalInfo.city}
                        onChange={(event, newValue) => {
                            console.log(newValue);

                            let tempValue = newValue === null ? {
                                city: "",
                                district: "",
                                firstLetter: ""
                            }: newValue;
                            
                            setPersonalInfo((data) => ({
                                ...data,
                                ["city"]: tempValue.city
                            }));
                            
                        }}
                        sx={{ width: "100%", fontSize: "1.4rem", marginTop: "1.5vw"}}
                        renderInput={(params) => <TextField 
                            {...params}
                            id="city"
                            label="Населено място" 
                            name="city"
                            variant="outlined"
                            color="success"
                            value={personalInfo.city}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                console.log("CITY:" + personalInfo.city);
                                
                                setPersonalInfo((data) => ({
                                    ...data,
                                    [event.target.name]: event.target.value,
                                }));
                            }}                         
                        />}
                    />
                        
                    {/* Телефонен номер */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw", display: "flex", flexDirection: "row"}}>
                        {/* КОД на държавата */}
                        <Autocomplete
                            id="country-select-demo"
                            sx={{ width: "8rem" }}
                            options={countries}
                            autoHighlight
                            inputValue={personalInfo.code}
                            onChange={(event, newValue) => {
                                console.log(newValue);
    
                                let tempValue = newValue === null ? {
                                    code: "",
                                    label: "",
                                    phone: ""
                                }: newValue;
                                
                                setPersonalInfo((data) => ({
                                    ...data,
                                    ["code"]: tempValue.phone
                                }));
                                
                            }}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props: any, option) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <Box
                                        key={key}
                                        component="li"
                                        sx={{ '& > img': { mr: 1.2, flexShrink: 0 }, fontSize: "1rem" }}
                                        {...optionProps}
                                    >
                                        <img
                                        loading="lazy"
                                        width="16"
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        alt=""
                                        />
                                        <span style={{ fontSize: "0.8rem" }}>{option.phone}</span>
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Код"
                                    name="code"
                                    variant="outlined"
                                    color="success"
                                    sx={{ fontSize: "0.8rem" }}
                                    value={personalInfo.code}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        console.log("CODE: " + personalInfo.code);
                                        
                                        setPersonalInfo((data) => ({
                                            ...data,
                                            [event.target.name]: event.target.value,
                                        }));
                                    }} 
                                />
                            )}
                        />

                        {/* Номер */}
                        <TextField
                            id="phone"
                            label="Телефонен номер"
                            name="phone"
                            value={personalInfo.phone}
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", fontSize: "1.4rem"}}
                            onChange={ handlePhoneNumber }
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {(personalInfo.phone.length == 10 && !phoneError) && (
                                            <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1rem" }}/>
                                        )}
                                    </InputAdornment>),
                            }}
                        />
                    </FormControl>

                    {/* № на документ */}
                    {profileType === "vet" && (
                        <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                            <TextField
                                id="vetDocumentNumber"
                                label="№ на документ"
                                name="vetDocumentNumber"
                                value={personalInfo.vetDocumentNumber}
                                variant="outlined"
                                color="success"
                                sx={{ width: "100%", fontSize: "1.4rem"}}
                                onChange={ handleDocumentNumber }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {personalInfo.vetDocumentNumber.length === 8 && (
                                                <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1rem" }}/>
                                            )}
                                            {personalInfo.vetDocumentNumber.length > 8 && (
                                                <DoDisturbAltOutlinedIcon color="success" sx={{ color: "#b20000", fontSize: "1rem" }}/>
                                            )}
                                        </InputAdornment>),
                                }}
                            />
                        </FormControl>
                    )}
                    
                </section>
            )}

            {(activeStep === 2 && (profileType === "company")) && (
                <section>
                    <p className="register-form-info-message">Моля, попълнете данните на Вашата фирма</p>

                    {/* Име на фирмата*/}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <TextField
                            id="companyName"
                            label="Име на фирмата"
                            name="companyName"
                            value={companyInfo.companyName}
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", fontSize: "1.4rem"}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCompanyInfo((data) => ({
                                  ...data,
                                  [event.target.name]: event.target.value,
                                }));
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {companyInfo.companyName.length >= 3 && (
                                                <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1rem" }}/>
                                        )}
                                    </InputAdornment>),
                            }}
                        />
                    </FormControl>

                    {/* ЕИК */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <TextField
                            id="EIK"
                            label="ЕИК на фирмата"
                            name="EIK"
                            value={companyInfo.EIK}
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", fontSize: "1.4rem"}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCompanyInfo((data) => ({
                                  ...data,
                                  [event.target.name]: event.target.value,
                                }));
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {companyInfo.EIK.length >= 3 && (
                                                <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1rem" }}/>
                                        )}
                                    </InputAdornment>),
                            }}
                        />
                    </FormControl>

                    {/* Адрес на фирмата */}
                    <FormControl sx={{ width: "100%", marginTop: "1.5vw"}}>
                        <TextField
                            id="address"
                            label="Адрес на фирмата"
                            name="address"
                            value={companyInfo.address}
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", fontSize: "1.4rem"}}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCompanyInfo((data) => ({
                                  ...data,
                                  [event.target.name]: event.target.value,
                                }));
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {companyInfo.address.length >= 3 && (
                                                <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "1rem" }}/>
                                        )}
                                    </InputAdornment>),
                            }}
                        />
                    </FormControl>
                </section>
            )}
            
            {/* BUTTONS */}
            <div className="register-buttons-container">
                <Button
                    sx={{ width: "7rem", padding: "0.6rem 3rem", fontSize: "0.7rem", backgroundColor: "#65C466", "&:hover": {backgroundColor: "#509C51", transform: "scale(1.03)"} }}
                    variant="contained"
                    color="success"
                    onClick={ () => {
                        handleActiveStep("back"); 
                    }}
                >
                    <WestIcon sx={{ fontSize: "1.5rem", mr: "0.5vw"}}></WestIcon>
                    НАЗАД
                </Button>   

                {activeStep < 2 && (
                    <Button
                    sx={{ width: "7rem", padding: "0.6rem 3rem", fontSize: "0.7rem", backgroundColor: "#65C466", "&:hover": {backgroundColor: "#509C51", transform: "scale(1.03)"} }}
                    variant="contained"
                    color="success"
                    disabled={ checkFormFields() }
                    onClick={ () => { 
                        handleActiveStep("forward"); 
                    }}
                    >
                        НАПРЕД
                        <EastIcon sx={{ fontSize: "1.5rem", ml: "0.5vw"}}></EastIcon>
                    </Button>
                )}
                
                {activeStep === 2 && (
                    <Button
                        sx={{ width: "9rem", padding: "0.6rem 3rem", fontSize: "0.9rem", backgroundColor: "#65C466", "&:hover": {backgroundColor: "#509C51", transform: "scale(1.03)"} }}
                        variant="contained"
                        color="success"
                        disabled={ checkFormFields() }
                        onClick={ handleSubmit }
                    >
                        РЕГИСТРИРАНЕ
                    </Button>  
                )}         
            </div>
        </div>
    );
};
