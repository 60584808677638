import React from "react";
import { useEffect } from "react";
import "./stories.css";

import bg from "../../../locales/bg.json";

export const Stories = () => {
  const stories = [
    {
      name: "Димитър Пенев",
      quote: '"Не успявам да намеря подходящ партньор на Самур"',
      img: require("./images/about_dimitar.jpg"),
      text: `Запознайте се с Димитър, който също като своя екип, има куче на име Самур. 
      Самур е на 6 години - средноазиатска овчарка, позната още като Алабай. 
      С внушителния си вид Самур често всява страх в непознати минувачи, 
      но за Димитър и семейството му той е досущ като по-малкото, но значително по-лакомо дете в къщата.
      Като собственик на тази екзотична порода, Димитър изпитва значителни затруднения 
      при търсенето на подходящ партньор за Самур. Това го подтиква към идеята, 
      заедно със своите колеги ентусиасти, да се справят с този проблем, 
      както и да намерят решение на редица други затруднения, 
      срещани от всеки собственик на четириноги приятели.`
    },
    {
      name: "Иван Данчев",
      quote: '"Рядко успявах да намирам отговори на въпросите, които си задавах, когато се учех как да отглеждам куче"',
      img: require("./images/about_danchev.jpg"),
      text: `Това е Иван. Иван има женски Лабрадор Ретривър, на име Айра, от 2020-та година. 
      Неговата любов към животните се заражда, когато е едва на 5 години. 
      Тогава баща му купува Немска Овчарка, която 2 години по-късно е подарена, 
      поради невъзможността на родителите му да й предоставят възможността да тича, 
      колкото е необходимо, за да расте здраво куче. 
      По същото време Иван има и котка – Мери. През 2022-ра година, 
      Иван намира съмишленици, с които решава да създаде нещо полезно както за собствениците на домашни любимци, 
      така и за самите животни.`
    },
    {
      name: "Иван Странски",
      quote: '"Изпитах огромни затруднения, докато успях да намеря подходящо куче"',
      img: require("./images/image-daniel.jpg"),
      text: `Това е Иван. Иван има мъжки Бигъл, Бък, присъединил се към семейството му през 2020 година. 
      За Иван Бък е близък приятел и незаменима част от семейството му. При търсенето на домашен любимец  
      Иван и семейството му са изпитали големи затруднения в намирането на правилното за тях куче. 
      Това го кара да намери съмишленици, с които да намерят начин да помогнат на бъдещи и настоящи 
      собственици на домашни любимци да избегнат такива и подобни проблеми.`
    },
    {
      name: "Илиян Неделчев",
      quote: '"Много трудно открих ветеринарен лекар, който да помогне на моето куче"',
      img: require("./images/about_iliyan.jpg"),
      text: `Запознайте се с Илиян. Той още от малък копнее за домашен любимец, 
      но през ранните си години няма възможност да гледа такъв. 
      Когато се изнася да живее самостоятелно той приютява изоставено бебе кученце, 
      което кръщава Роки. Роки се оказва със заболяване, което ако не се лекува веднага може да е фатално. 
      Тази случка кара Илиян да осъзнае колко важно е да можеш да намериш бързо добър специалист за своя любимец 
      и когато идеята за Pet Connect се заражда, той я посреща с отворени обятия. `
    },
    {
      name: 'Д-р Любомир Спасов',
      quote: '',
      title: 'консултант',
      img: require("./images/consultant.png"),
      text: `С над 35 години опит в сферата на ветеринарната медицина и собствена амбулатория, 
      д-р Спасов е дългогодишен член на Националния Съвет на Българския Ветеринарен Съюз - Oбластна Колегия Пазарджик. 
      Той е регистриран ветеринарен лекар със смесена практика.`
    }
  ];

  useEffect(() => {
      const heading = document.querySelector(".heading-animated") as HTMLElement;
      const gridItems = document.querySelectorAll(
        ".animated-grid-item"
      ) as NodeListOf<HTMLElement>;

      const observer2 = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("animate-in");
              observer2.unobserve(entry.target);
            }
          });
        }
      );

      gridItems.forEach((element) => {
        observer2.observe(element);
      });

      observer2.observe(heading);

    },[]);

  const logoWhite = require('../../../assets/paw-white.png');
  const logoBlack = require('../../../assets/paw-glitch.png');


  return (
    <div className="stories-container">
      <div className="u-center-text u-margin-bottom-small">
        <h3 className="heading_secondary heading-stories heading-animated">
          Екипът на PetConnect
        </h3>
      </div>

      <div className="grid-container">
        <div className="grid-item item1">
          <article className="testimonial bg-primary-400 quote text-neutral-100 animated-grid-item">
              <div className="flex">
                <div>
                  <img src={stories[0].img} className="border-primary-500" alt="daniel clifford" />
                </div>
                <div>
                  <h3 className="name">
                    {stories[0].name}
                  </h3>
                </div>
              </div>
              <p className="testimonial__review-heading">{stories[0].quote}</p>
              <p className="testimonial__review-text">{stories[0].text}</p>
          </article>
        </div>
        <div className="grid-item item2">
          <article className="testimonial bg-primary-300 text-neutral-100 animated-grid-item">
              <div className="flex">
                <div>
                  <img src={stories[3].img} className="border-primary-500" alt="Kira Whittle" />
                </div>
                <div>
                  <h3 className="name">
                    {stories[3].name}
                  </h3>
                </div>
              </div>
              <p className="testimonial__review-heading">{stories[3].quote}</p>
              <p className="testimonial__review-text">{stories[3].text}</p>
              <div style={{marginTop: "3rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <img src={logoWhite} className="bottom_image"></img>
              </div>
              
          </article>
        </div>
        <div className="grid-item item3">
          <article className="testimonial bg-neutral-100 text-secondary-400 animated-grid-item">
              <div className="flex">
                <div>
                  <img src={stories[2].img} className="border-primary-300" alt="Jeanette Harmon" />
                </div>
                <div>
                  <h3 className="name">
                    {stories[2].name}
                  </h3>
                </div>
              </div>
              <p className="testimonial__review-heading">{stories[2].quote}</p>
              <p className="testimonial__review-text">{stories[2].text}</p>
              <div style={{marginTop: "3rem", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <img src={logoBlack} className="bottom_image"></img>
              </div>
          </article>
        </div>  
        <div className="grid-item item4">
          <article className="testimonial bg-primary-100 text-secondary-400 animated-grid-item">
              <div className="flex">
                <div>
                  <img src={stories[4].img} className="border-primary-400" alt="Jeanette Harmon" />
                </div>
                <div>
                  <h3 className="name">
                    {stories[4].name}
                  </h3>
                  <h3 className="job-title">
                    {stories[4].title}
                  </h3>
                </div>
              </div>
              <p className="testimonial__review-heading">{stories[4].quote}</p>
              <p className="testimonial__review-text" style={{ fontWeight: "500" }}>{stories[4].text}</p>
          </article>
        </div>
        <div className="grid-item item5">
          <article className="testimonial bg-secondary-400 text-neutral-100 quote animated-grid-item">
              <div className="flex">
                <div>
                  <img src={stories[1].img} className="border-primary-400" alt="Jonathan Walters" />
                </div>
                <div>
                  <h3 className="name">
                    {stories[1].name}
                  </h3>
                </div>
              </div>
              <p className="testimonial__review-heading">{stories[1].quote}</p>
              <p className="testimonial__review-text">{stories[1].text}</p>
          </article>
        </div>
      </div>

    </div>
  );
};
